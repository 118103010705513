
import BookingForm from "./BookingForm";
import {COMMON_RESPONSE} from "../../lib/const";

export const MultipleDayBooking = () => {
    return(<>
    <BookingForm bookingType={COMMON_RESPONSE.MULTIPLE_DAY}/>
    </>);
}

export default MultipleDayBooking;
