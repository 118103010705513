import animation from "./paymentFailed.json";
import Lottie from "lottie-react";
import './lottie.scss'
import {useEffect} from "react";
import {useNavigates} from "../../routes/NavigateContext";
import {ROUTES} from "../../lib/const";

const style ={
    width:"20rem"
}
const PaymentFailureLoading = () => {
    const navigate=useNavigates();
    useEffect(()=>{
        setTimeout(()=>{
            navigate(ROUTES.DASHBOARD)
        },2000)
    })
    return(
        <div className={"success_wrapper"}>
            <Lottie animationData={animation} style={style} loop={false}/>
            <h3 className="cancel">Payment Failed</h3>
        </div>
    )
}
export default PaymentFailureLoading;
