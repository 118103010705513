import axios, {AxiosResponse, Method} from 'axios';
import {ApplicationError} from "../lib/ApplicationError";
import {APIConstant, APIEndpoint} from "./APIConstant";
import { store} from "../store/store";
import {
    AutoCompleteResponse,
    CommonResponse, CouponResponse, IHistoryResponse,
    IOtpValidateResponse, IProfileUpdateRequest, IProfileUpdateResponse, IReacableResponse,
    IRegisterRequest,
    IRegisterResponse, ISlotResponse, ISubscriptionResponse,
    IUserDetail,
    OtpInput, PaymentResponseDto, SendSelectedMapData
} from "../lib/customerInterface/interfaces";
import {Simulate} from "react-dom/test-utils";

import {IServiceResponse} from "../lib/dto/IServiceResponse";
import {BookingRequestDto, BookingResponseDto} from "../lib/dto/BookingDto";
import {
    IAddressDeleteResponseDto,
    IAddressRequestDto,
    IAddressResponseDto,
    ICancelSubscriptionResponse
} from "../lib/dto/AddressDto";


interface RequestConfig {
    headers?: Record<string, string>;
    params?: Record<string, string | number>;
}

class API {
    private setLoader = () => {
    };

    private hideLoader = () => {
    };
    private static instance: API = new API()

    static getInstance() {
        return API.instance;
    }

    intialize(showLoader: () => void,
              hideLoader: () => void) {
        this.setLoader = showLoader;
        this.hideLoader = hideLoader;
    }


    async fetchData<T>(
        method: Method,
        endpoint: string,
        requestData?: any,
        config?: RequestConfig
    ): Promise<T> {
        this.setLoader()
        try {
            const axiosConfig: RequestConfig = {};

            if (config && config.headers) {
                axiosConfig.headers = config.headers;
            }

            if (config && config.params) {
                axiosConfig.params = config.params;
            }

            let response: AxiosResponse<T>;

            const baseUrl = process.env.REACT_APP_API_BASE_URL;
            const k: RequestConfig = {
                headers: {
                    'Content-Type': 'application/json',
                    ...axiosConfig.headers

                },
                params: {
                    ...axiosConfig.params
                }
            }
            console.log(k)

            switch (method) {
                case 'GET':
                    response = await axios.get(`${baseUrl}${endpoint}`, k);

                    break;
                case 'POST':
                    response = await axios.post(`${baseUrl}${endpoint}`, requestData, k);
                    break;
                case 'PUT':
                    response = await axios.put(`${baseUrl}${endpoint}`, requestData, k);
                    break;

                // Add more cases for other HTTP methods as needed
                default:
                    throw new Error(`Unsupported HTTP method: ${method}`);
            }
            // return { data: response.data, headers: response.headers };
            return response.data;
        } catch (error: any) {
            console.log("error is ", error)
            if (error.message === "Network Error") {
                throw new ApplicationError("NETWORK ERROR", 50000)
            } else {

                // Handle errors appropriately
                if (error?.response.status === 400 || error?.response.status === 401 || error?.response.status === 500)
                    throw new ApplicationError(
                        error.message, error.response.status
                    );
                else {

                    throw new ApplicationError("NETWORK ERROR", 50000)
                }
            }

        } finally {
            this.hideLoader();
        }
    }

    login(request: IRegisterRequest): Promise<CommonResponse<IRegisterResponse>> {
        return this.fetchData<CommonResponse<IRegisterResponse>>("POST", APIEndpoint.REGISTER, request)
            .then(response => Promise.resolve(response))
            .catch(err => {
                this.handleErrorResponse(err);
                return Promise.reject(err);
            })

    }

    validateOtp(request: OtpInput): Promise<CommonResponse<IOtpValidateResponse>> {
        return this.fetchData<CommonResponse<IOtpValidateResponse>>("POST", APIEndpoint.VALIDATE_OTP, request)
            .then(response => Promise.resolve(response))
            .catch(err => {
                this.handleErrorResponse(err);
                return Promise.reject(err);
            })

    }
    resendOTP(request: IRegisterRequest): Promise<CommonResponse<IRegisterResponse>> {
        return this.fetchData<CommonResponse<IRegisterResponse>>("POST", APIEndpoint.RESEND_OTP, request)
            .then(response => Promise.resolve(response))
            .catch(err => {
                this.handleErrorResponse(err);
                return Promise.reject(err);
            })

    }

    getUserDetail(customerId: string, token: string): Promise<CommonResponse<IUserDetail>> {
        const requestHeader: RequestConfig = {
            headers: {
                "AUTHORIZATION": `Bearer ${token}`
            },
            params: {
                customerId: customerId
            }
        }
        return this.fetchData<CommonResponse<IUserDetail>>("GET", APIEndpoint.CUSTOMER_DETAIL, null, requestHeader)
            .then(response => Promise.resolve(response))
            .catch(err => {
                this.handleErrorResponse(err);
                return Promise.reject(err);
            })


    }

    updateUserProfile(request: IProfileUpdateRequest): Promise<CommonResponse<IProfileUpdateResponse>> {
        const requestHeader: RequestConfig = {
            headers: {
                "AUTHORIZATION": `Bearer ${store.getState().authSlice.token}`
            }
        }
        return this.fetchData<CommonResponse<IProfileUpdateResponse>>("PUT", APIEndpoint.UPDATE_PROFILE, request, requestHeader)
            .then(response => Promise.resolve(response))
            .catch(err => {
                this.handleErrorResponse(err);
                return Promise.reject(err);
            })
    }

    serviceReachable(pincode: string): Promise<CommonResponse<IReacableResponse>> {
        const requestHeaderAndParam: RequestConfig = {
            headers: {
                AUTHORIZATION: `Bearer ${store.getState().authSlice.token}`
            },
            params: {
                pinCode: pincode
            }
        }
        return this.fetchData<CommonResponse<IReacableResponse>>("GET", APIEndpoint.SERVICE_REACHABLE, null,
            requestHeaderAndParam)
            .then(response => Promise.resolve(response))

            .catch(err => {
                this.handleErrorResponse(err);
                return Promise.reject(err);
            })
    }

    autoComplete(address: string): Promise<CommonResponse<AutoCompleteResponse>> {
        const requestHeaderAndParam: RequestConfig = {
            headers: {
                AUTHORIZATION: `Bearer ${store.getState().authSlice.token}`
            },
            params: {
                address: address
            }
        }
        return this.fetchData<CommonResponse<AutoCompleteResponse>>("GET", APIEndpoint.AUTO_COMPLETE, null,
            requestHeaderAndParam)
            .then(response => Promise.resolve(response))

            .catch(err => {
                this.handleErrorResponse(err);
                return Promise.reject(err);
            })
    }

    getServiceDetail(pincode:string): Promise<CommonResponse<IServiceResponse>> {
        const requestHeaderAndParam: RequestConfig = {
            headers: {
                AUTHORIZATION: `Bearer ${store.getState().authSlice.token}`
            },
            params: {
                pinCode: pincode
            }
        }
        return   APIS.fetchData<CommonResponse<IServiceResponse>>("GET", APIEndpoint.SERVICE, null, requestHeaderAndParam)
            .then(response => Promise.resolve(response))

            .catch(err => {
                this.handleErrorResponse(err);
                return Promise.reject(err);
            })
    }


    getBookingDetail(): Promise<CommonResponse<ISubscriptionResponse[]>> {
        const requestHeaderAndParam: RequestConfig = {
            headers: {
                AUTHORIZATION: `Bearer ${store.getState().authSlice.token}`
            }
        }
        return this.fetchData<CommonResponse<ISubscriptionResponse[]>>("GET", APIEndpoint.BOOKING_DETAIL, null,
            requestHeaderAndParam)
            .then(response => Promise.resolve(response))

            .catch(err => {
                this.handleErrorResponse(err);
                return Promise.reject(err);
            })
    }


    getBookingHistory(): Promise<CommonResponse<IHistoryResponse[]>> {
        const requestHeaderAndParam: RequestConfig = {
            headers: {
                AUTHORIZATION: `Bearer ${store.getState().authSlice.token}`
            }
        }
        return this.fetchData<CommonResponse<IHistoryResponse[]>>("GET", APIEndpoint.BOOKING_HISTORY, null,
            requestHeaderAndParam)
            .then(response => Promise.resolve(response))

            .catch(err => {
                this.handleErrorResponse(err);
                return Promise.reject(err);
            })
    }


    initiateBooking(request:BookingRequestDto): Promise<CommonResponse<BookingResponseDto>> {
        const requestHeaderAndParam: RequestConfig = {
            headers: {
                AUTHORIZATION: `Bearer ${store.getState().authSlice.token}`
            }
        }
        return this.fetchData<CommonResponse<BookingResponseDto>>("POST", APIEndpoint.INITIATE_BOOKING, request,
            requestHeaderAndParam)
            .then(response => Promise.resolve(response))

            .catch(err => {
                this.handleErrorResponse(err);
                return Promise.reject(err);
            })
    }

    SendSelectedMapData(request:SendSelectedMapData): Promise<CommonResponse<IServiceResponse>> {
        const requestHeaderAndParam: RequestConfig = {
            headers: {
                AUTHORIZATION: `Bearer ${store.getState().authSlice.token}`
            }
        }
        return this.fetchData<CommonResponse<IServiceResponse>>("POST", APIEndpoint.BY_ADDRESS, request,
            requestHeaderAndParam)
            .then(response => Promise.resolve(response))

            .catch(err => {
                this.handleErrorResponse(err);
                return Promise.reject(err);
            })
    }


    validateBooking(customerId:string,orderId:string): Promise<CommonResponse<PaymentResponseDto>> {
        const requestHeaderAndParam: RequestConfig = {
            headers: {
                AUTHORIZATION: `Bearer ${store.getState().authSlice.token}`
            },
            params:{
                customerId:customerId,
                orderId:orderId
            }
        }
        return this.fetchData<CommonResponse<PaymentResponseDto>>("GET",APIEndpoint.VERIFY_PAYMENT,null,requestHeaderAndParam)
            .then(response => Promise.resolve(response))

            .catch(err => {
                this.handleErrorResponse(err);
                return Promise.reject(err);
            })
    }


    addAddress(request:IAddressRequestDto): Promise<CommonResponse<IAddressResponseDto>> {
        const requestHeaderAndParam: RequestConfig = {
            headers: {
                AUTHORIZATION: `Bearer ${store.getState().authSlice.token}`
            }
        }
        return this.fetchData<CommonResponse<IAddressResponseDto>>("POST", APIEndpoint.ADD_ADDRESS, request, requestHeaderAndParam)
            .then(response => Promise.resolve(response))

            .catch(err => {
                this.handleErrorResponse(err);
                return Promise.reject(err);
            })
    }


    getSlot(pincode:string,date:string): Promise<CommonResponse<ISlotResponse>> {
        const requestHeaderAndParam: RequestConfig = {
            headers: {
                AUTHORIZATION: `Bearer ${store.getState().authSlice.token}`
            },
            params: {
                "pincode": pincode,
                "date": date
            }
        }
        return this.fetchData<CommonResponse<ISlotResponse>>("GET", APIEndpoint.GET_SLOT, null, requestHeaderAndParam)
            .then(response => Promise.resolve(response))

            .catch(err => {
                this.handleErrorResponse(err);
                return Promise.reject(err);
            })
    }



    getCoupon(): Promise<CommonResponse<CouponResponse[]>> {
        const requestHeaderAndParam: RequestConfig = {
            headers: {
                AUTHORIZATION: `Bearer ${store.getState().authSlice.token}`
            },

        }
        return   APIS.fetchData<CommonResponse<CouponResponse[]>>("GET", APIEndpoint.COUPON, null, requestHeaderAndParam)
            .then(response => Promise.resolve(response))

            .catch(err => {
                this.handleErrorResponse(err);
                return Promise.reject(err);
            })
    }
    cancelSubscription(bookingId:string ): Promise<CommonResponse<ICancelSubscriptionResponse>> {
        const requestHeaderAndParam: RequestConfig = {
            headers: {
                AUTHORIZATION: `Bearer ${store.getState().authSlice.token}`
            },
            params:{
                "bookingId": bookingId
            }
        }
        return this.fetchData<CommonResponse<ICancelSubscriptionResponse>>("PUT", APIEndpoint.CANCEL_SUBSCRIPTION, null,
            requestHeaderAndParam)
            .then(response => Promise.resolve(response))

            .catch(err => {
                this.handleErrorResponse(err);
                return Promise.reject(err);
            })
    }

    deleteAddress(address:string): Promise<CommonResponse<IAddressDeleteResponseDto>> {
        const requestHeaderAndParam: RequestConfig = {
            headers: {
                AUTHORIZATION: `Bearer ${store.getState().authSlice.token}`,

            },
            params:{
                addressId: address
            }
        }
        return this.fetchData<CommonResponse<IAddressDeleteResponseDto>>("GET", APIEndpoint.DELETE_ADDRESS, null, requestHeaderAndParam)
            .then(response => Promise.resolve(response))

            .catch(err => {
                this.handleErrorResponse(err);
                return Promise.reject(err);
            })
    }

    handleErrorResponse(err: ApplicationError) {
        return err;
    }

}


const APIS = API.getInstance();
export default APIS;
