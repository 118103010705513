import {MonthlyCoupon, multiDay, OneDayCoupon, OneTimeCoupon, perDay, SlideOne, WeeklyCoupon} from "./const";

export const CONTENT_DATA = {
    "login": {
        heading: "GET STARTED",
        subheading: "Welcome To COYD",
        slogan: "Book a chef on your demand",
        input: "Enter Mobile Number",
        errMessage: "Invalid Mobile Number",
        btnName: "Submit",
        maxLength: 10,
        endPoint: "customer/register"
    },
    "otp": {
        heading: "ENTER OTP",
        invalidOTP: "Invalid OTP",
        didYouReceiveOTP: "Did not receive OTP yet? Click to Resend",
        resend: "Resend OTP",
        btnName: "Verify",
        editMobile: "Edit Mobile Number",
        maxLength: 4,
        endPoint: "customer/validate/otp",
        resendEndPoint: "customer/resend/otp"
    },
    "profileUpdate": {
        heading: "ENTER DETAILS",
        subheading: "Welcome onboard!",
        slogan: "Dive into the details ahead",
        btnName: "Submit",
        name: "Enter Name*",
        email: "Enter Email",
        maxLength: 30,
        endPoint: "customer/update"

    },
    "pinCode": {
        heading: "Enter Your Location",
        btnName: "Submit",
        input: "Enter area or street",
        maxLength: 50,

    },
    "bottomMenu": {
        home: "HOME",
        subscription: "SUBSCRIPTION",
        history: "HISTORY",
        profile: "PROFILE",
        chatUs: "CHAT"

    },
    "homePage": {

        services: {
            heading: "Services",
            type: [
                {
                    type: "Per Day",
                    img: perDay
                },
                {
                    type: "Multiple Days",
                    img: multiDay
                }
            ]
        }
    },
    bookingType: {

        type: "PER DAY",
        heading: "Slot Booking",
        date: "Date",
        slot: "Slot",
        cuisineType: "Select Cuisine Type",
        numberOfPerson: "Number Of Person",
        numberOfBurner: "Number Of Burner",
        closeBtn: "Close",
        okBtn: "Select",
        subscriptionType: "Select Your Plan",
        CURRENT_PINCODE:'change '


    },
    slots: [
        "6-8AM",
        "8-10AM",
        "10-12PM",
        "12-2PM",
        "2-4PM",
        "4-6PM",
        "6-8PM",
        "8-10PM"
    ],
    availabeSlots: [
        "6-8AM",
        "8-10AM",

    ],
    cuisineType: [
        "North Indian",
        "South Indian"
    ],
    numberOfPerson: [1, 2, 3, 4, 5, 6],
    numberOfBurner: ["1", "2", "3", "4", "5", "6", "induction"],
    subscriptionPlan: ["WEEKLY", "FIFTEEN DAYS", "MONTHLY"],

    address: {
        heading: "Address",
        houseNo: "House Number/Flat No",
        landmark: "Landmark",
        houseNoLength: 10,
        streetAddress: "Street Address",
        area:"area",
        pincode: "Pin code",
        city: "City",
        state: "State",
        addressType: "Address Type",
        addressName: "Address Name",
        nameLength: 30,
        addAddressBtn: "Submit",
        selectAddressPlaceHolder: "Select Street / Area",
        selectAddress: "Select Address"
    },
    selectAddress: [
        {
            addressName: "Souraj Home",
            addressId: "12345",
            address: "105, Lakshminarayana Pura, AECS Layout, Bangalore, Karnataka, 560087",
        },
        {
            addressName: "Prateek Address",
            addressId: "1234578",
            address: "105, Lakshminarayana Pura, AECS Layout, Bangalore, Karnataka, 560087",
        },
        {
            addressName: "Pratsan",
            addressId: "12345789",
            address: "105, Lakshminarayana Pura, AECS Layout, Bangalore, Karnataka, 560087",
        },


    ],
    selectAddressHeading:"CHOOSE ADDRESS",
    contactUsHeading:"CONTACT US",
    mobileNumber:"+918296265704",
    email:"hello@coyd-service.com",
    termsAndCondition:"Terms And Condition",
    slides: [
        {
            name: "SlideOne",
            img: SlideOne
        },
        {
            name: "OneTimeCoupon",
            img: OneTimeCoupon
        },
        {
            name: "OneDayCoupon",
            img: OneDayCoupon
        },
        {
            name: "WeeklyCoupon",
            img: WeeklyCoupon
        },
        {
            name: "MonthlyCoupon",
            img: MonthlyCoupon
        },
    ],
    history: {
        bookingId: "Booking Id: ",
        Paid: "Paid",
        bookingType: "One Day",
        slot: ["6-8 PM", "10-12AM"],
        numberOfPerson: " Person",
        address: "Spice Garden",
        bookingDate: "23/01/2024",
        status: "Completed",
        amount: "Paid: ₹",
        heading:" Booking History"

    },

    subscription: {
        heading:"Booking Detail",
        bookingId: "123145666",
        amount: "Paid: ₹",
        couponSaved: "Saved: ₹",
        actualAmount: "Subscription price: ₹",
        bookingType: "Monthly",
        slot: ["6-8 PM", "10-12AM"],
        numberOfPerson: "6 Person",
        address: "Spice Garden",
        bookingDate: "23/01/2024",
        subscriptionStatus: "Active",

        daysLeft: " days left",
        fromAndTo: "21 to 31 AUG",
        otp: "OTP: ",
        slotDetail: [
            {
                time: "Slot: 6-8 PM",
                status: "CONFIRM",
                chefDetail:{
                    chefName: "Prateek",
                    chefImg: "www.google.com"
                },


            },
            {

                time: "Slot: 10-12 PM",
                status: "PENDING",
                otp: null,
                chefDetail: null
            }
        ]
    },
    bookingPreview:{
       heading:"Booking Preview",
        cuisineType:"Cuisine Type:",
        subscriptionType:"Plan Type:",
        people:"People:",
        burner:"Burner:",
        startDate:"Start Date:",
        slot1:"Slot1:",
        slot2:"Slot2:",
        proceedToPayBtn:"Proceed To Pay",
        editDetails:"Edit Details",
        addressName: "Souraj Home",
        addressId: "12345",
        address: "105, Lakshminarayana Pura, AECS Layout, Bangalore, Karnataka, 560087",
        amount:399,
        tax:"include taxes",
        SLOT_DETAIL:'Slot Details:',
        FOOD_DETAIL:'Food Details:',
        OTHER_DETAIL:'Others Details:'

    },
    payment:{
        SUCCESS:"SUCCESS",
        FAILURE:"FAILURE"
    },
    profilePage:{
        address:"Address",
        edit:"Edit & Add Address",
        logoutBtn:"logout",
        contactUs:"Contact Us",
        termsAndCondition:"Terms And Condition"
    },

    months : [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ],
    errorMessage:{
        serviceNotAvailable:"Service Not available",
        TEMPORARILY_SERVICE_NOT_AVAILABLE_AT: "Temporarily service not available",
        invalidStreet:"Invalid Area / Street"
    }



}