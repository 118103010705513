import React, {useState, useEffect} from 'react';
import './Popups.scss';
import Popup from "../../helper/Popup";
import {useGlobalModalContext} from "../../helper/GlobalModal";
import {CONTENT_DATA} from "../../lib/content";
import {AppDispatch, RootState} from "../../store/store";
import {useDispatch, useSelector} from "react-redux";
import {sliceDetails} from "../../store/slice/AuthSlice";
import { useNavigates } from '../../routes/NavigateContext';
import {updateActiveSlot, updateSlot} from "../../store/slice/StateUtil"; // Update the path as per your file structure


const Slot: React.FC = () => {
    const activeColor: string = "#FF4F3B";
    const disableColor: string = "#a6a6a61a";
    const {hideModal} = useGlobalModalContext();
    const navigate=useNavigates();


    const dispatch = useDispatch<AppDispatch>();
    const activeSlots = useSelector((state: RootState) => state.StateUtil.activeSlots);
    const avaialableSlots = useSelector((state: RootState) => state.StateUtil.slot.slots);
    const [selectedSlots, setSelectedSlots] = useState<string[]>(activeSlots);
    // Load active slots on component mount
    console.log("available slots are ",avaialableSlots)
    useEffect(() => {
        setSelectedSlots(activeSlots);
    }, [activeSlots]);

    // Dispatch the selected slots whenever it changes
    useEffect(() => {
        // if(selectedSlots.length!==0) {
        console.log("total ", selectedSlots)
        dispatch(updateActiveSlot(selectedSlots));
        // }

    }, [selectedSlots]);


    const toggleSlotSelection = (slot: string) => {
        if (selectedSlots.includes(slot)) {
            const filterSlot = selectedSlots.filter(selectedSlot => selectedSlot !== slot)
            if (filterSlot.length === 0) {
                setSelectedSlots([]);
            } else {
                setSelectedSlots(filterSlot);
            }

        } else {
            if (selectedSlots.length < 2) {
                console.log("two");
                setSelectedSlots([...selectedSlots, slot]);
            }
        }
    };
    // todo show only available slot slot

    return (
        <Popup contentClassName="slots-wrapper"  >
            <div className="slots">
                {CONTENT_DATA.slots.map((value) => (
                    <p
                        className={!avaialableSlots.includes(value) ? "disable" : selectedSlots.includes(value) ? 'selected' : ''}
                        style={{backgroundColor: !avaialableSlots.includes(value) ? disableColor : selectedSlots.includes(value) ? activeColor : ''}}
                        key={value}
                        onClick={(e) => {
                            if (e.currentTarget.className !== "disable")
                                toggleSlotSelection(value)
                        }}
                    >
                        {value}
                    </p>
                ))}
            </div>
            <button type="button"  onClick={() => {
                hideModal(true)
            }}>{selectedSlots.length > 0 ? CONTENT_DATA.bookingType.okBtn : CONTENT_DATA.bookingType.closeBtn}</button>
            <p className="error">*max 2 slots can be selected</p>
        </Popup>
    )
}

export default Slot;
