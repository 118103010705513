import Popup from "../../helper/Popup";
import APIS from "../../util/APIUtil";
import {APIConstant} from "../../util/APIConstant";
import {updateSubscriptionList} from "../../store/slice/StateUtil";
import {showToast} from "../../helper/GlobalToast";
import {ApplicationError} from "../../lib/ApplicationError";
import {StorageManager} from "../../util/DataUtil";
import {resetAllRecord} from "../../store/slice/AuthSlice";
import {AppDispatch, store} from "../../store/store";
import {useDispatch} from "react-redux";
import React from "react";
import {useGlobalModalContext} from "../../helper/GlobalModal";
import '../../styles/global.scss';

type BookingId ={
    bookingId:string;
}
const CancelSubscription:React.FC<BookingId>=({bookingId})=>{
    const {hideModal} =useGlobalModalContext();
    const dispatch=useDispatch<AppDispatch>();
    const filterSubscriptionList=(bookingId:string)=>{
        const subscriptionList = store.getState().StateUtil.subscriptionList;
        if(subscriptionList)
            return subscriptionList.filter(subscriptionResponse=>subscriptionResponse.bookingId!==bookingId);

    }
    const cancelSubscription=(bookingId:string)=>{

        APIS.cancelSubscription(bookingId)
            .then(response=>{
                if(response.statusCode===APIConstant.SUCCESS_STATUS_CODE) {
                    console.log("success")
                    //     todo filter and remove that specific booking id
                    const filterSubscriptionList1 = filterSubscriptionList(bookingId);
                    if(filterSubscriptionList1){
                        console.log(filterSubscriptionList1)
                        dispatch(updateSubscriptionList(filterSubscriptionList1))}
                    showToast(APIConstant.SUBSCRIPTION_CANCELLED_SUCCESSFULLY)
                    hideModal();
                }
                if(response.statusCode===APIConstant.FAILURE_STATUS_CODE && response.statusMessage===APIConstant.SUBSCRIPTION_IN_PROGRESS)
                    showToast(APIConstant.SUBSCRIPTION_IN_PROGRESS)

            }).catch(err=> {
            if (err instanceof ApplicationError) {
                switch (err.statusCode) {
                    case APIConstant.BAD_REQUEST:
                        showToast(APIConstant.BAD_REQUEST_MESSAGE);
                        break;
                    case APIConstant.INTERNAL_SERVER_ERROR:
                        showToast(APIConstant.INTERNAL_SERVER_ERROR_MESSAGE);
                        break;
                    case  APIConstant.UNAUTHORIZED: {
                        showToast(APIConstant.UNAUTHORIZED_MESSAGE)
                        StorageManager.deleteData("user")
                        dispatch(resetAllRecord(""))
                        break;
                    }
                }
            }
        })}

    return(
        <Popup contentClassName="cancelSubscription"  modalBgClass="cancel">
            <p>Are you sure to cancel the subscription?</p>
            <div className="cancle-btn">
                <button onClick={()=>cancelSubscription(bookingId)}>Yes</button>
                <button className="" onClick={()=>hideModal()}>No</button>
            </div>
        </Popup>
    )
}
export default CancelSubscription;