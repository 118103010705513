


import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import rotReducer from  "./slice/rootReducer";
import storage from 'redux-persist/lib/storage';
const persistConfig = {
    key: 'user',
    storage,
};

// Wrap reducers with persistReducer
const persistedReducer = persistReducer(persistConfig, rotReducer);


// Create the Redux store
export const store = configureStore(
    {
        reducer:persistedReducer
    }
);

// Export persistor
export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
