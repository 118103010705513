import './Dashboard.scss';


import {COMMON_RESPONSE} from "../../lib/const";

import BookingForm from "./BookingForm";

const PerDayBooking = () => {


    return (
        <>
            <BookingForm bookingType={COMMON_RESPONSE.PER_DAY} />

        </>
    );
}

export default PerDayBooking;
