import Popup from "../../helper/Popup";
import {CONTENT_DATA} from "../../lib/content";

import React from "react";
import './Popups.scss'
import {useGlobalModalContext} from "../../helper/GlobalModal";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
const ContactUs=()=>{
    const contactNumber = useSelector((state: RootState) => state.StateUtil.serviceDetail.contactNumber);
    const {hideModal}=useGlobalModalContext();
    return(
    <Popup modalBgClass="contactUs-popup" canClose={()=>hideModal()}>
    <p className="title self-center">{CONTENT_DATA.contactUsHeading}</p>
        <a href={`tel:${contactNumber}`}><p className="title self-center">{contactNumber}</p></a>
    <p className="title self-center">{CONTENT_DATA.email}</p>



    </Popup>
)
}
export default ContactUs;