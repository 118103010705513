import './Popups.scss'
import Popup from "../../helper/Popup";
import {PincodeValidation} from "../../schemas/FormErrors";
// import {CommonResponse, IReacableResponse, PredictionData, SendSelectedMapData}
import {CONTENT_DATA} from "../../lib/content";
import {MapStaic, PincodeImg} from "../../lib/const";
import {Form, Formik} from "formik";
import React, {useEffect, useState} from "react";
import {useGlobalModalContext} from "../../helper/GlobalModal";
import APIS from "../../util/APIUtil";
import {APIConstant} from "../../util/APIConstant";

import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store/store";
import {
    IServiceState,
    updateNoService,
    updateAutoAddressAndPincode,
    updateServiceDetail, IAutoAddress, IPredictedAddressData, updatePredictedAddress
} from "../../store/slice/StateUtil";
import {ApplicationError} from "../../lib/ApplicationError";
import {StorageManager} from "../../util/DataUtil";
import {resetAllRecord} from "../../store/slice/AuthSlice";
import {showToast} from "../../helper/GlobalToast";
import APIUtil from "../../util/APIUtil";
import {PredictionData, SendSelectedMapData} from "../../lib/customerInterface/interfaces";
import {truncateText} from "../../util/AppUtils";


const PinCode = () => {
    const {hideModal} = useGlobalModalContext();

    const dispatch = useDispatch<AppDispatch>();
    const pincode = useSelector((state: RootState) => state.StateUtil.autoAddress.pincode);
    const area = useSelector((state: RootState) => state.StateUtil.autoAddress.area);
    const servinceNotAvaialble = useSelector((state: RootState) => state.StateUtil.service.serviceNotAvailable);
    const noService = useSelector((state: RootState) => state.StateUtil.service.noService);
    const token = useSelector((state: RootState) => state.authSlice.token);
    const [fieldValue, setFieldValue] = useState<string>();
    const [mapData, setMapData] = useState<PredictionData[]>([]);
    const [addressAdded, setAddressAdded] = useState<boolean>(false)
    // const const [isServiceable, set]
    // console.log(fieldValue)

    useEffect(() => {
        const finalData = setTimeout(() => {
            if (fieldValue === "") setMapData([]);
            if (fieldValue !== undefined && fieldValue !== "") {
                APIUtil.autoComplete(fieldValue).then((res) => {
                    console.log(res)
                    setMapData(res.data.predictions);
                })
            }
        }, 500)
        return () => clearTimeout(finalData);
    }, [fieldValue])
    const validateServiceAvailability = (city: string, lat: string, lng: string, mainText: string, secondaryText: string, pincode: string) => {
        const request: SendSelectedMapData = {
            city: city,
            latitude: lat,
            longitude: lng
        }
        APIUtil.SendSelectedMapData(request).then(res => {
                console.log("service available response ", res)


                if (res.statusCode === APIConstant.SUCCESS_STATUS_CODE) {
                    setAddressAdded(true);
                    const serviceState: IServiceState = {
                        serviceNotAvailable: false,
                        noService: false

                    }
                    const autoAddress: IAutoAddress = {
                        pincode: res.data.details.pincode,
                        area: mainText
                    };
                    dispatch(updateAutoAddressAndPincode(autoAddress))
                    dispatch(updateNoService(serviceState));
                    dispatch(updateServiceDetail(res.data));
                    hideModal();
                    // store the autocomplete data
                    const predictData: IPredictedAddressData = {
                        city: city,
                        pinCode: pincode,
                        geometry: {
                            location: {
                                lat: lat,
                                lng: lng
                            }
                        },
                        structured_formatting: {
                            main_text: mainText,
                            secondary_text: secondaryText
                        }
                    }
                    dispatch(updatePredictedAddress(predictData));

                }

                if (res.statusCode === APIConstant.FAILURE_STATUS_CODE && res.statusMessage === APIConstant.NOT_ACCEPTING_ANY_FURTHER_REQUEST) {
                    // hideModal();
                    setAddressAdded(false);
                    console.log("temporary called")
                    const serviceState: IServiceState = {
                        serviceNotAvailable: true,
                        noService: false
                    }
                    dispatch(updateNoService(serviceState));
                    // todo route to service not avaialble
                    //     navigates(ROUTES.NOT_SERVICEABLE)
                }

                if (res.statusCode === APIConstant.FAILURE_STATUS_CODE && res.statusMessage === APIConstant.SERVICE_NOT_AVAILABLE) {
                    // hideModal();
                    setAddressAdded(false)
                    const serviceState: IServiceState = {
                        serviceNotAvailable: false,
                        noService: true
                    }
                    console.log("service not available")
                    dispatch(updateNoService(serviceState));
                    // navigates(ROUTES.NO_SERVICE)
                }
            }
        ).catch(err => {
            if (err instanceof ApplicationError) {
                switch (err.statusCode) {
                    case APIConstant.BAD_REQUEST:
                        showToast(APIConstant.BAD_REQUEST_MESSAGE);
                        break;
                    case APIConstant.INTERNAL_SERVER_ERROR:
                        showToast(APIConstant.INTERNAL_SERVER_ERROR_MESSAGE);
                        break;
                    case  APIConstant.UNAUTHORIZED: {
                        showToast(APIConstant.UNAUTHORIZED_MESSAGE)
                        StorageManager.deleteData("user")
                        dispatch(resetAllRecord(""))
                        break;
                    }
                    case APIConstant.NETWORK_ERROR:
                        showToast(APIConstant.NETWORK_ERROR_MESSAGE)
                        break;
                }


            }
        });
    }

    return (
        <Popup modalBgClass="pincode-context" canClose={() => {
            if ( !servinceNotAvaialble || !noService) {
                hideModal()
            }


        }}>

            <Formik
                key="pincode-form"
                initialValues={{
                    pincode: "",
                }}
                validationSchema={PincodeValidation}
                onSubmit={(values, errors) => {

                    APIS.serviceReachable(values.pincode)
                        .then(res => {
                            if (res.statusCode === APIConstant.SUCCESS_STATUS_CODE) {
                                const serviceState: IServiceState = {
                                    serviceNotAvailable: false,
                                    noService: false
                                }

                                // dispatch(updateAutoAddressAndPincode(values.pincode))
                                dispatch(updateNoService(serviceState));
                                APIS.getServiceDetail(values.pincode).then(response => {
                                    hideModal();
                                    if (response.statusCode === APIConstant.SUCCESS_STATUS_CODE) {
                                        dispatch(updateServiceDetail(response.data))
                                    }
                                }).catch(err => {
                                    if (err instanceof ApplicationError) {
                                        switch (err.statusCode) {
                                            case APIConstant.BAD_REQUEST:
                                                showToast(APIConstant.BAD_REQUEST_MESSAGE);
                                                break;
                                            case APIConstant.INTERNAL_SERVER_ERROR:
                                                showToast(APIConstant.INTERNAL_SERVER_ERROR_MESSAGE);
                                                break;
                                            case  APIConstant.UNAUTHORIZED: {
                                                showToast(APIConstant.UNAUTHORIZED_MESSAGE)
                                                StorageManager.deleteData("user")
                                                dispatch(resetAllRecord(""))
                                                break;
                                            }
                                            case APIConstant.NETWORK_ERROR:
                                                showToast(APIConstant.NETWORK_ERROR_MESSAGE)
                                                break;
                                        }


                                    }
                                });
                            } else {
                                hideModal();
                                if (res.statusCode === APIConstant.FAILURE_STATUS_CODE && res.statusMessage === APIConstant.NOT_ACCEPTING_ANY_FURTHER_REQUEST) {

                                    const serviceState: IServiceState = {
                                        serviceNotAvailable: true,
                                        noService: false
                                    }
                                    dispatch(updateNoService(serviceState));
                                    // todo route to service not avaialble
                                    //     navigates(ROUTES.NOT_SERVICEABLE)
                                }

                                if (res.statusCode === APIConstant.FAILURE_STATUS_CODE && res.statusMessage === APIConstant.SERVICE_NOT_AVAILABLE) {
                                    const serviceState: IServiceState = {
                                        serviceNotAvailable: false,
                                        noService: true
                                    }
                                    dispatch(updateNoService(serviceState));
                                    // navigates(ROUTES.NO_SERVICE)
                                }
                            }
                        }).catch(err => {
                        if (err instanceof ApplicationError) {
                            switch (err.statusCode) {
                                case APIConstant.BAD_REQUEST:
                                    showToast(APIConstant.BAD_REQUEST_MESSAGE);
                                    break;
                                case APIConstant.INTERNAL_SERVER_ERROR:
                                    showToast(APIConstant.INTERNAL_SERVER_ERROR_MESSAGE);
                                    break;
                                case  APIConstant.UNAUTHORIZED: {
                                    showToast(APIConstant.UNAUTHORIZED_MESSAGE)
                                    StorageManager.deleteData("user")
                                    dispatch(resetAllRecord(""))
                                    break;
                                }
                            }


                        }
                    })

                }}
            >
                {({
                      values,
                      handleChange,
                      handleSubmit,
                      handleBlur,
                      errors,
                  }) => (
                    <Form className="pincode-wapper" onSubmit={handleSubmit}>
                        <section className="pin-header">
                            <img src={PincodeImg} alt={'Pincode Img'}/>
                            <p className="title small-font">{CONTENT_DATA.pinCode.heading}</p>
                        </section>
                        <input className="searchInput" type="text" name="pincode"
                               maxLength={CONTENT_DATA.pinCode.maxLength}
                               placeholder={CONTENT_DATA.pinCode.input}
                               autoComplete="off"
                               onBlur={handleBlur}
                               onChange={(e) => {
                                   setFieldValue(e.target.value);
                                   handleChange(e);
                                   setAddressAdded(true);

                               }}
                               value={values.pincode}/>
                        {errors.pincode && (
                            <p className="error">{CONTENT_DATA.errorMessage.invalidStreet}</p>
                        )}

                       {/* {
                            noService && !addressAdded ?
                                <p>No Service Available</p> : servinceNotAvaialble && !addressAdded ?
                                    <p>{CONTENT_DATA.errorMessage.serviceNotAvailable}</p> :

                                    <section className="map-response">
                                        {mapData.map(({structured_formatting, pinCode, geometry, city}) => (

                                            <div className="response-data"
                                                 onClick={() => validateServiceAvailability(city, geometry.location.lat, geometry.location.lng, structured_formatting.main_text, structured_formatting.secondary_text, pincode)}>
                                                <img src={MapStaic} className="static-img" alt="MapStaic"/>
                                                <section className="text-wrapper">
                                                    <p className="primary-text">{truncateText(structured_formatting.main_text, 38)}</p>
                                                    <p className="secondary-text">{structured_formatting.secondary_text}</p>
                                                </section>
                                            </div>
                                        ))}
                                    </section>
                        }*/}
                        <>
                            {noService && !addressAdded ? (
                                <p>No Service Available</p>
                            ) : servinceNotAvaialble && !addressAdded ? (
                                <p>{CONTENT_DATA.errorMessage.serviceNotAvailable}</p>
                            ) : (
                                <section className="map-response">
                                    {mapData.map(({ structured_formatting, pinCode, geometry, city }) => (
                                        <div
                                            className="response-data"
                                            onClick={() =>
                                                validateServiceAvailability(
                                                    city,
                                                    geometry.location.lat,
                                                    geometry.location.lng,
                                                    structured_formatting.main_text,
                                                    structured_formatting.secondary_text,
                                                    pinCode
                                                )
                                            }
                                        >
                                            <img src={MapStaic} className="static-img" alt="MapStaic" />
                                            <section className="text-wrapper">
                                                <p className="primary-text">
                                                    {truncateText(structured_formatting.main_text, 38)}
                                                </p>
                                                <p className="secondary-text">
                                                    {structured_formatting.secondary_text}
                                                </p>
                                            </section>
                                        </div>
                                    ))}
                                </section>
                            )}
                        </>
                    </Form>
                )}
            </Formik>
        </Popup>
    )
}
export default PinCode;