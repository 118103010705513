import {createBrowserRouter} from "react-router-dom";
import {ROUTES} from "../lib/const";
import StaticPages from "../pages/staticPages/StaticPages";
import Login from "../pages/staticPages/Login";
import Otp from "../pages/staticPages/Otp";
import ProfileUpdate from "../pages/staticPages/ProfileUpdate";
import Dashboard from "../pages/dashboard/Dashboard";
import Home from "../pages/dashboard/Home";
import BookingHistory from "../pages/dashboard/BookingHistory";
import Profile from "../pages/dashboard/Profile";
import PageNotFound from "../pages/staticPages/payment/PageNotFound";
import PerDayBooking from "../pages/dashboard/PerDayBooking";
import MultipleDayBooking from "../pages/dashboard/MultipleDayBooking";
import Subscription from "../pages/dashboard/Subscription";
import BookingPreview from "../pages/dashboard/BookingPreview";
import NoService from "../pages/staticPages/error/NoService";
import NoInternet from "../pages/staticPages/error/NoInternet";
import NotServicable from "../pages/staticPages/error/NotServicable";
import PrivateRoute from "../helper/PrivetRoute";
import {NavigateProvider} from "./NavigateContext";
import {GlobalModal} from "../helper/GlobalModal";
import PaymentFailure from "../pages/staticPages/error/PaymentFailure";
import PaymentSuccess from "../pages/staticPages/error/PaymentSuccess";
import React from "react";
import Coupon from "../components/popups/Coupon";
import ApplyCoupon from "../pages/dashboard/ApplyCoupon";
import AddAddress from "../pages/dashboard/AddAddress";


const router = createBrowserRouter([
    {
        path: ROUTES.STATIC_PAGES,
        element: <StaticPages/>,
        errorElement: <PageNotFound/>,
        // todo add error element page
        children: [
            {
                element:<GlobalModal> <Login/></GlobalModal>,
                index: true,

            },
            {
                path: ROUTES.OTP,
                element: <Otp/>
            },
            {
                path: ROUTES.PROFILE_UPDATE,
                element: <PrivateRoute><ProfileUpdate/></PrivateRoute>
            }
        ]
    },
    {
        path: ROUTES.DASHBOARD,
        element: <NavigateProvider>
            <PrivateRoute><GlobalModal children={<Dashboard key={"dashboard"}/>}></GlobalModal></PrivateRoute>
        </NavigateProvider>,
        children: [
            {
                index: true,
                // path: ROUTES.DASHBOARD,
                element: <PrivateRoute><Home key={"home"}/></PrivateRoute>,


            },

            {
                element: <PrivateRoute><Subscription/></PrivateRoute>,
                path: ROUTES.BOOKING,

            },
            {
                element: <PrivateRoute><BookingHistory/></PrivateRoute>,
                path: ROUTES.HISTORY,
            },
            {
                element: <PrivateRoute><Profile/></PrivateRoute>,

                path: ROUTES.PROFILE,
            },
            {
                element: <PrivateRoute children=<NotServicable/>/>,
                path: ROUTES.NOT_SERVICEABLE
            },
            {
                element: <PrivateRoute children={<NoService/>}/>,
                path: ROUTES.NO_SERVICE
            },


        ]
    },
    {
        element: <NavigateProvider>
            <GlobalModal children={<PrivateRoute children={<PerDayBooking/>}/>}/>

        </NavigateProvider>,


        path: ROUTES.PER_DAY_BOOKING,
    },
    {
        element: <NavigateProvider>
            <GlobalModal>
                <PrivateRoute>
                    <MultipleDayBooking/>
                </PrivateRoute>
            </GlobalModal>
        </NavigateProvider>,
        path: ROUTES.MULTIPLE_DAY_BOOKING
    },
    {
        element: <NavigateProvider>
            <GlobalModal>
                <PrivateRoute>
                    <AddAddress/>
                </PrivateRoute>
            </GlobalModal>
        </NavigateProvider>,
        path: ROUTES.ADD_ADDRESS
    },
    {
        element:<NavigateProvider>
            <GlobalModal>
            <PrivateRoute children={<BookingPreview/>}></PrivateRoute></GlobalModal></NavigateProvider> ,
        path: ROUTES.BOOKING_PREVIEW
    },
    {
        element:<NavigateProvider>

                <PrivateRoute children={<ApplyCoupon/>}></PrivateRoute></NavigateProvider> ,
        path: ROUTES.COUPON
    },

    {
        element: <NoInternet/>,
        path: ROUTES.NO_INTERNET
    },
    {
        element: <NavigateProvider> <PrivateRoute children={<PaymentSuccess/>}/></NavigateProvider>,
        path: ROUTES.PAYMENT_SUCCESS
    },
    {
        element: <NavigateProvider> <PrivateRoute children={<PaymentFailure/>}/></NavigateProvider>,
        path: ROUTES.PAYMENT_FAILURE
    },

    {
        path: "/*",
        element: <PageNotFound/>
    }
], {basename: process.env.REACT_APP_BASE_URL});
export default router;
