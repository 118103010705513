import {useNavigate} from "react-router-dom";
import {Logo} from "../../lib/const";
import { ReactComponent as Dropdown} from '../../assets/images/dropdown.svg'
import { ReactComponent as Location} from '../../assets/images/location_track.svg'
import {MODAL_TYPES, useGlobalModalContext} from "../../helper/GlobalModal"
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {truncateText} from "../../util/AppUtils";
const Header = () => {
    const navigate = useNavigate();
    const {showModal} =useGlobalModalContext();
    const pincode = useSelector((state: RootState) => state.StateUtil.autoAddress.pincode);
    const area = useSelector((state: RootState) => state.StateUtil.autoAddress.area);
    return (
        <div className="header header-main" >

            <section className="location-sec" onClick={() => showModal(MODAL_TYPES.PINCODE)}>
                <div className="location-top">
                    <Location/>

                    <p className='pincode-text'>{truncateText(area, 15)}</p>

                    <Dropdown  />
                </div>
            </section>
            <img src={Logo} alt="Logo"/>
        </div>
    )
}
export default Header;