import React from "react";
import './SubscriptionCard.scss'
import {SubscriptionCardInterface} from "../../lib/customerInterface/interfaces";


const SubscriptionCard:React.FC<SubscriptionCardInterface> = ({name,image,onclick}) => {
  return(
      <div className="subs-card" onClick={onclick}>
          <img src={image} alt={name} />
          <p>{name}</p>
      </div>
  )
}
export default SubscriptionCard;