export  class ApplicationError extends Error{
    public statusCode: number;
    constructor(message: string,statusCode:number) {
    super(message);
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, ApplicationError.prototype);
    // Set the name of the error
    this.name = 'ApplicationError';
    this.statusCode=statusCode;
}

}