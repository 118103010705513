import './Dashboard.scss';
import {CONTENT_DATA} from "../../lib/content";
import {Form, Formik} from "formik";
import React, {useEffect, useRef, useState} from "react";
import DatePicker from 'react-datepicker';
import {BackArrow, COMMON_RESPONSE, ROUTES, Slot, TOAST_MESSAGE} from "../../lib/const";
import {MODAL_TYPES, useGlobalModalContext} from "../../helper/GlobalModal";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store/store";
import CustomDropdown, {Option} from "../../components/dropdown/CustomDropdown";
import {useNavigate} from "react-router-dom";
import {ReactComponent as Add} from "../../assets/images/add.svg";
import APIS from "../../util/APIUtil";
import {APIConstant} from "../../util/APIConstant";
import {
    CurrentBooking,
    updateActiveSlot,
    updateBooking,
    updateServiceDetail,
    updateSlot
} from "../../store/slice/StateUtil";
import {ReactComponent as Edit} from "../../assets/images/edit.svg";
import {AddressDto} from "../../lib/dto/AddressDto";

import {showToast} from "../../helper/GlobalToast";
import AddressDetails from "../../components/addressDetails/AddressDetails";
import {ApplicationError} from "../../lib/ApplicationError";
import {resetAllRecord} from "../../store/slice/AuthSlice";
import {StorageManager} from "../../util/DataUtil";
import {truncateText} from "../../util/AppUtils";

interface BookingFormProps {
 bookingType:string
}
const BookingForm :React.FC<BookingFormProps> = ({bookingType}) => {
    const navigate = useNavigate();
    const {showModal} = useGlobalModalContext();
    const {hideModal} = useGlobalModalContext();
    const [selectedDate, setSelectedDate] = useState<any>();
    const [startDate, setStartDate] = useState<string>("");
    const [cuisineType, setCuisineType] = useState<string>('');
    const [numberOfPerson, setNumberOfPerson] = useState<number>(0);
    const [burner, setNumberOfBurner] = useState<string>("");
    const [subscription, setSubscription] = useState<string>("");
    const [error, setError] = useState<boolean>(false);
    const cuisineRef = useRef(null);
    const personRef = useRef(null);
    const subscriptionRef = useRef(null);
    const activeSlots:string[] = useSelector((state: RootState) => state.StateUtil.activeSlots);
    const [slots,setSlots]=useState<string[]>(activeSlots)
    const pincode: string = useSelector((state: RootState) => state.StateUtil.autoAddress.pincode);
    const area: string = useSelector((state: RootState) => state.StateUtil.autoAddress.area);
    // const area: string = useSelector((state: RootState) => state.StateUtil.autoAddress.area);
    const serviceDetail = useSelector((state: RootState) => state.StateUtil.serviceDetail)
    const activeAddress: AddressDto = useSelector((state: RootState) => state.UserSlice.activeAddress);
    const addressList: AddressDto[] = useSelector((state: RootState) => state.UserSlice.address);
    const activeBooking: CurrentBooking = useSelector((state: RootState) => state.StateUtil.booking);
    const dispatch = useDispatch<AppDispatch>();


    const [clearCuisineSelection, setClearCuisineSelection] = useState<() => void>();
    const [clearPersonSelection, setClearPersonSelection] = useState<() => void>();
    const [clearSubscriptionSelection, setClearSubscriptionSelection] = useState<() => void>();


    const maxDate = new Date();
    const minDate = new Date();
    maxDate.setDate(maxDate.getDate() + 30);

    const goBack = () => {
        const bookingUpdate: CurrentBooking = {
            pincode: "",
            slot: [],
            cuisineType: '',
            numberOfPerson: 0,
            startDate: '',
            chefPreferred: '',
            numberOfBurner: ''
        }
        dispatch(updateBooking(bookingUpdate))
       dispatch(updateActiveSlot([]))
        hideModal();
        navigate(-1);
    }
    const validateData = (bookingUpdate: CurrentBooking) => {
        console.log("validating request ", bookingUpdate)

        if (bookingUpdate.slot.length === 0 || !bookingUpdate.cuisineType || !bookingUpdate.startDate || bookingUpdate.numberOfPerson===0) {

            showToast(TOAST_MESSAGE.ALL_FIELDS_ARE_MANDATORY);
            return false;
        } else
            if(bookingType===COMMON_RESPONSE.MULTIPLE_DAY && !subscription){
                showToast(TOAST_MESSAGE.ALL_FIELDS_ARE_MANDATORY);
                return false;
            }
                else
            return true;
    }
    const next = () => {
        const bookingUpdate: CurrentBooking = {
            slot: activeSlots,
            cuisineType: cuisineType,
            numberOfPerson: numberOfPerson,
            startDate: startDate,
            chefPreferred: '',
            numberOfBurner: burner,
            pincode: activeAddress.pincode,
            subscriptionType: bookingType === COMMON_RESPONSE.MULTIPLE_DAY ? subscription : undefined
        }
        console.log("booking update is", bookingUpdate)

        if (validateData(bookingUpdate)) {
            if(activeAddress.mainAddress!==area) {
                const containAddress = addressList.some(obj => obj.mainAddress === area);
                if(containAddress)
                    showModal(MODAL_TYPES.SELECT_ADDRESS)
                else {
                    navigate(ROUTES.ADD_ADDRESS);
                    return;
                }
            }
            else {
                setError(false);
                dispatch(updateBooking(bookingUpdate));
                navigate(ROUTES.BOOKING_PREVIEW);
            }
            // if(activeAddress.mainAddress!==pincode){
            //     showToast(`${TOAST_MESSAGE.ADDRESS_NOT_MATCHED_WITH_PINCODE} ${pincode}`)
            //     return;
            // }
            // if (validateData(bookingUpdate)) {
            //     setError(false);
            //     if (activeAddress.pincode !== pincode) {
            //         APIS.getServiceDetail(activeAddress.pincode)
            //             .then(response => {
            //                 if (response.statusCode === APIConstant.SUCCESS_STATUS_CODE) {
            //                     const responseData = response.data;
            //                     dispatch(updateServiceDetail(responseData));
            //                 }
            //             })
            //     }
            // setError(false);
            // dispatch(updateBooking(bookingUpdate));
            // navigate(ROUTES.BOOKING_PREVIEW);
        } else {
            setError(true);
        }
    }


    const handleCuisineChange = (selectedValue: string | number) => {
        setCuisineType(String(selectedValue));
    };
    const handleSubscriptionChange = (selectedValue: string | number) => {
        setSubscription(String(selectedValue));
    };
    const handlePersonChange = (selectedValue: string | number) => {
        setNumberOfPerson(Number(selectedValue));
    };
    const cuisineTypeList: Option<string> = {
        value: serviceDetail.cuisineType,
    }
    const numberofPersonList: Option<number> = {
        value: CONTENT_DATA.numberOfPerson
    }
    const subscriptionList: Option<string> = {
        value: CONTENT_DATA.subscriptionPlan
    }
    const DisplaySlot = (value: string) => {
        return (
            <p key={value}>{value}</p>
        )
    }

    useEffect(() => {
        if (
            activeBooking &&
            (
                activeBooking.slot.length > 0 ||
                activeBooking.cuisineType !== '' ||
                activeBooking.numberOfPerson > 0 ||
                activeBooking.startDate !== '' ||
                activeBooking.numberOfBurner !== ''
            )
        ) {

            setCuisineType(String(activeBooking.cuisineType));
            setNumberOfPerson(Number(activeBooking.numberOfPerson));
            setNumberOfBurner(String(activeBooking.numberOfBurner));
            setStartDate(activeBooking.startDate);
            if(activeBooking.startDate!=='')
            setSelectedDate(new Date(activeBooking.startDate.split('-').reverse().join('-')));
            console.log("is this useeffect called? ",cuisineType)
            if(bookingType===COMMON_RESPONSE.MULTIPLE_DAY && activeBooking.subscriptionType)
                setSubscription(activeBooking.subscriptionType)

        }
    }, [activeBooking]);


    const clearSelection = () => {
        if (clearCuisineSelection) clearCuisineSelection();
        if (clearPersonSelection) clearPersonSelection();

        if (clearSubscriptionSelection) clearSubscriptionSelection();
    }

    useEffect(()=>{
        setSlots(activeSlots)
    },[activeSlots])

    useEffect(() => {
        if(activeAddress)
        if(activeAddress.pincode && pincode!==activeAddress.pincode) {
            console.log("booking form pincode ",pincode);
            console.log("booking form active address ",activeAddress);
            // dispatch((updatePincode(activeAddress.pincode)))
            clearSelection();
            setCuisineType("");
            setNumberOfPerson(0);
            setNumberOfBurner("");
            setSelectedDate('')
        }

    }, [activeAddress]);
    const getDate = (currentDate: Date) => {
        console.log("current date is ", currentDate)

        const day = currentDate.getDate().toString().padStart(2, '0'); // Get day with leading zero if needed
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        const year = currentDate.getFullYear();

        return `${day}-${month}-${year}`;

    }
    const getSlotDetail = (date: Date) => {

        setSelectedDate(date)
        const date1 = getDate(date);
        setStartDate(date1)
        console.log(date1)
        console.log("pincode is ", pincode)
        dispatch(updateActiveSlot([]))
        //     todo make an api call
        APIS.getSlot(pincode,date1).then(response => {
            if (response.statusCode === APIConstant.SUCCESS_STATUS_CODE) {
                const slot = response.data.slots;
                dispatch((updateSlot(slot)))
            }
            else {
                dispatch(updateSlot([]))
            }
        }).catch(err => {
            if (err instanceof ApplicationError) {
                switch (err.statusCode) {
                    case APIConstant.BAD_REQUEST:
                        showToast(APIConstant.BAD_REQUEST_MESSAGE);
                        break;
                    case APIConstant.INTERNAL_SERVER_ERROR:
                        showToast(APIConstant.INTERNAL_SERVER_ERROR_MESSAGE);
                        break;
                    case  APIConstant.UNAUTHORIZED: {
                        showToast(APIConstant.UNAUTHORIZED_MESSAGE)
                        StorageManager.deleteData("user")
                        dispatch(resetAllRecord(""))
                        break;
                    }
                }


            }
        })
    }



    return (
        <>
            <div className="booking-page-wrapper">
                <Formik initialValues={{}} onSubmit={() => { }}>
                    {({ handleSubmit }) => (
                        <Form className="booking-page" onSubmit={handleSubmit}>
                            <img className="back-arrow" src={BackArrow} alt="BackArrow" onClick={goBack}/>
                            <p className="title">{CONTENT_DATA.bookingType.heading}</p>
                            <div className="input-wrapper">
                                <label className="active-pin-change"><span>Change:</span> {truncateText(area, 11)} <Edit onClick={()=>showModal(MODAL_TYPES.PINCODE)}/></label>
                            </div>
                            <DatePicker
                                icon={
                                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M19.875 3.02722H17.625V2.27722C17.625 2.07831 17.546 1.88754 17.4053 1.74689C17.2647 1.60624 17.0739 1.52722 16.875 1.52722C16.6761 1.52722 16.4853 1.60624 16.3447 1.74689C16.204 1.88754 16.125 2.07831 16.125 2.27722V3.02722H8.625V2.27722C8.625 2.07831 8.54598 1.88754 8.40533 1.74689C8.26468 1.60624 8.07391 1.52722 7.875 1.52722C7.67609 1.52722 7.48532 1.60624 7.34467 1.74689C7.20402 1.88754 7.125 2.07831 7.125 2.27722V3.02722H4.875C4.47718 3.02722 4.09564 3.18526 3.81434 3.46656C3.53304 3.74787 3.375 4.1294 3.375 4.52722V19.5272C3.375 19.925 3.53304 20.3066 3.81434 20.5879C4.09564 20.8692 4.47718 21.0272 4.875 21.0272H19.875C20.2728 21.0272 20.6544 20.8692 20.9357 20.5879C21.217 20.3066 21.375 19.925 21.375 19.5272V4.52722C21.375 4.1294 21.217 3.74787 20.9357 3.46656C20.6544 3.18526 20.2728 3.02722 19.875 3.02722ZM7.125 4.52722V5.27722C7.125 5.47613 7.20402 5.6669 7.34467 5.80755C7.48532 5.9482 7.67609 6.02722 7.875 6.02722C8.07391 6.02722 8.26468 5.9482 8.40533 5.80755C8.54598 5.6669 8.625 5.47613 8.625 5.27722V4.52722H16.125V5.27722C16.125 5.47613 16.204 5.6669 16.3447 5.80755C16.4853 5.9482 16.6761 6.02722 16.875 6.02722C17.0739 6.02722 17.2647 5.9482 17.4053 5.80755C17.546 5.6669 17.625 5.47613 17.625 5.27722V4.52722H19.875V7.52722H4.875V4.52722H7.125ZM19.875 19.5272H4.875V9.02722H19.875V19.5272Z"
                                            fill="black"/>
                                    </svg>
                                }
                                showIcon={true}
                                onChange={(date: Date) => getSlotDetail(date)}
                                dateFormat="dd-MM-yyyy"
                                placeholderText="Select a date"
                                selected={selectedDate}
                                minDate={minDate}
                                maxDate={maxDate}


                            />
                            {bookingType===COMMON_RESPONSE.MULTIPLE_DAY && (
                            <div className="input-wrapper">
                                <label>{CONTENT_DATA.bookingType.subscriptionType}</label>
                                <CustomDropdown
                                    id="subscription-type"
                                    options={subscriptionList}
                                    onChange={handleSubscriptionChange}
                                    placeHolder="Select Subscription Type"
                                    refer={subscriptionRef}
                                    defaultValue={subscription}
                                    setClearFunction={setClearSubscriptionSelection}
                                />
                            </div>
                                )}

                            <div className={`timetable-wrapper ${!selectedDate ? "disable" : ""}`}
                                 onClick={(e) => {

                                     if (e.currentTarget.className !== "timetable-wrapper disable")
                                         showModal(MODAL_TYPES.SLOT)
                                 }

                                 }>
                                <section>
                                    {slots.length > 0 ?
                                        (activeSlots.map(((value) => (
                                            DisplaySlot(value)
                                        )))) : <p className="empty-slot">Select Slot</p>
                                    }
                                </section>
                                <img src={Slot}/>
                            </div>
                            <div className="input-wrapper">
                                <label>{CONTENT_DATA.bookingType.cuisineType}</label>
                                <CustomDropdown
                                    id="cuisine-type"
                                    options={cuisineTypeList}
                                    onChange={handleCuisineChange}
                                    placeHolder="Select Cuisine"
                                    refer={cuisineRef}
                                    defaultValue={cuisineType}
                                    setClearFunction={setClearCuisineSelection}
                                />
                            </div>
                            <div className="input-wrapper">
                                <label>{CONTENT_DATA.bookingType.numberOfPerson}</label>
                                <CustomDropdown
                                    id="number-of-person"
                                    options={numberofPersonList}
                                    onChange={handlePersonChange}
                                    placeHolder="Select Number of Persons"
                                    refer={personRef}
                                    defaultValue={numberOfPerson}
                                    setClearFunction={setClearPersonSelection}
                                />
                            </div>

                            {!activeAddress.addressId ?
                                <p className="large-font" onClick={() => {
                                    // hideModal();
                                    navigate(ROUTES.ADD_ADDRESS)
                                }}><Add onClick={() => navigate(ROUTES.ADD_ADDRESS)}/> Add Address*</p>
                                :  <AddressDetails
                                    key={activeAddress?.addressId}
                                    checked={true}
                                    handleCheck={() => showModal(MODAL_TYPES.SELECT_ADDRESS)}
                                    name={activeAddress.addressName}
                                    address={`${activeAddress.flatNo}, ${activeAddress.subAddress},${activeAddress.mainAddress}`}
                                    isButton={true}
                                    isButtonClick={() => showModal(MODAL_TYPES.SELECT_ADDRESS)}
                                    btnName="Change"
                                />}
                            {error && (

                                <p className="error">all fields are mandatory**</p>
                            )}

                            {/*<button type="button" className="clear-button" onClick={clearSelection}>Clear Selection</button>*/}
                            <button className="from-bottom" type="submit"
                                    onClick={() => next()}>{CONTENT_DATA.login.btnName}</button>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
}

export default BookingForm;
