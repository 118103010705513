import React, { useEffect, useState } from 'react';
import './CustomDropdown.scss';

export interface Option<T> {
    value: T[];
}

interface DropdownProps<T> {
    options: Option<T>;
    onChange: (selectedValue: T) => void;
    placeHolder?: string;
    refer?: React.RefObject<HTMLDivElement>;
    id?: string;
    setClearFunction?: (clearFunc: () => void) => void;
    defaultValue?: T;
    // Add defaultValue prop
}

const CustomDropdown = <T extends string | number>({
                                                       options,
                                                       onChange,
                                                       placeHolder = 'Select...',
                                                       refer,
                                                       id,
                                                       setClearFunction,
                                                       defaultValue
                                                   }: DropdownProps<T>) => {
    const [selectedValue, setSelectedValue] = useState<T | ''>(defaultValue || '');
    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect(() => {
        if (defaultValue !== undefined) {
            setSelectedValue(defaultValue);
        }
    }, [defaultValue]);

    useEffect(() => {
        if (setClearFunction) {
            setClearFunction(() => () => setSelectedValue('' as T));
        }
    }, [setClearFunction]);

    const handleSelectionChange = (value: T) => {
        setSelectedValue(value);
        onChange(value);
        toggleDropdown();
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const closeDropdown = () => {
        setIsOpen(false);
    };

    const handleDocumentClick = (event: MouseEvent) => {
        if (refer?.current && !refer.current.contains(event.target as Node)) {
            closeDropdown();
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleDocumentClick);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);

    return (
        <div ref={refer} className={`custom-dropdown ${isOpen ? 'open' : ''}`}>
            <div id={id} className="dropdown-header" onClick={toggleDropdown}>
                {selectedValue ? selectedValue : placeHolder}
            </div>
            {isOpen && (
                <div className="dropdown-options">
                    {options.value.map((option, index) => (
                        <div key={index} className="dropdown-option" onClick={() => handleSelectionChange(option)}>
                            {option}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CustomDropdown;
