import PinCode from "./Pincode";
import Slot from "./Slot";

import SelectAddress from "./SelectAddress";
import EditAddress from './EditAddress';
import EditPincode from "./EditPincode";
import ContactUs from "./ContactUs";
import TermsPopup from "./TermsPopUp";
import CancelSubscription from "./CancelSubscription";



const popups = {PinCode,Slot,SelectAddress,EditAddress,EditPincode,ContactUs,TermsPopup,CancelSubscription}

export default popups;