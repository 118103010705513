import {PropsWithChildren, useEffect, useState} from "react";
import {useGlobalModalContext} from "./GlobalModal";
import {useNavigate} from "react-router-dom";
import {useNavigates} from "../routes/NavigateContext";

export interface PopupProps extends PropsWithChildren {
    modalBgClass?: string;
    className?: string;
    contentClassName?: string;
    canClose?: () => void;
}

export default function Popup({modalBgClass, className, children,canClose,contentClassName}: PopupProps) {


const navigate=useNavigate();
    const {hideModal} = useGlobalModalContext();


    return (
        <div className={"popup " + className}>
            <div className="popup-bg" onClick={canClose } />
            <div className={"popup-modal " + modalBgClass}>
                <div className={`content ${contentClassName}`}>{children}</div>
            </div>
        </div>
    );
}
