import React, {useEffect, useState} from 'react';
import './StaticPages.scss';
import SecondaryLayout from "../../layout/SecondaryLayout";
import {CONTENT_DATA} from "../../lib/content";
import {LoginValidation} from "../../schemas/FormErrors";
import {Form, Formik} from "formik";
import APIS from '../../util/APIUtil';
import {useNavigate} from "react-router-dom";
import {IRegisterRequest} from "../../lib/customerInterface/interfaces";
import {getStarted, ROUTES} from "../../lib/const";
import {APIConstant} from "../../util/APIConstant";
import {useGlobalLoaderContext} from "../../helper/GlobalLoader";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store/store";
import {showToast} from "../../helper/GlobalToast";
import {mobileNumberDetails} from "../../store/slice/AuthSlice";
import CheckBox from "../../components/checkBox/CheckBox";
import {MODAL_TYPES, useGlobalModalContext} from "../../helper/GlobalModal";

const Login = () => {
    const navigate = useNavigate();
    const [flag, setFlag] = useState(true);
    const token = useSelector((state: RootState) => state.authSlice.token)
    const isProfileUpdated = useSelector((state: RootState) => state.authSlice.isProfileUpdated)
    const dispatch = useDispatch<AppDispatch>();
    const {showModal}=useGlobalModalContext();
    const getUserDetail = async () => {
        // showLoader();
        console.log("login token ", token)
        console.log("login profileUpdated ", isProfileUpdated)
        if (token) {
            if (isProfileUpdated) {

                console.log("token is ,", token)
                console.log(isProfileUpdated)

                setFlag(false)
                // hideLoader();
                navigate(ROUTES.DASHBOARD)

            } else {

                navigate(ROUTES.PROFILE_UPDATE)
                setFlag(false)

            }
        } else {

            setFlag(false)

        }


    }
    useEffect(() => {

        getUserDetail().then(r => setFlag(false));


    }, [token, flag]);
    const navigation = useNavigate();

    return (
        <>
            {!flag && (
                <SecondaryLayout image={getStarted}>

                    <Formik
                        key="Login-form"
                        initialValues={{
                            mobileNumber: "",
                            agree: false,
                        }}
                        validationSchema={LoginValidation}
                        onSubmit={(values, errors) => {

                            // navigation(ROUTES.DASHBOARD);

                            const data: IRegisterRequest = {
                                mobileNumber: values.mobileNumber
                            }
                            APIS.login(data)
                                .then(response => {
                                    if (response.statusCode === APIConstant.SUCCESS_STATUS_CODE) {
                                        dispatch(mobileNumberDetails(values.mobileNumber));
                                        sessionStorage.setItem("mobileNumber", values.mobileNumber);
                                        navigation(ROUTES.OTP);
                                    }
                                })
                                .catch(errors => {

                                    switch (errors.statusCode) {
                                        case APIConstant.BAD_REQUEST:
                                            showToast(APIConstant.BAD_REQUEST_MESSAGE);
                                            break;
                                        case APIConstant.INTERNAL_SERVER_ERROR:
                                            showToast(APIConstant.INTERNAL_SERVER_ERROR_MESSAGE);
                                            break;
                                    }

                                })




                        }}
                    >
                        {({
                              values,
                              handleChange,
                              handleSubmit,
                              handleBlur,
                              errors,
                            touched,
                              isValid
                          }) => (
                            <Form className="page-wrapper" onSubmit={handleSubmit}>
                                <h1 className="title">{CONTENT_DATA.login.heading}</h1>
                                <h2 className="sub-heading">{CONTENT_DATA.login.subheading}</h2>
                                <p className="slogan">{CONTENT_DATA.login.slogan}</p>
                                <input type="tel" name="mobileNumber" maxLength={CONTENT_DATA.login.maxLength}
                                       placeholder={CONTENT_DATA.login.input}
                                       autoComplete="off"
                                       onBlur={handleBlur}
                                       onChange={handleChange}
                                       value={values.mobileNumber}/>

                                <div className="tandc-check">
                                    <CheckBox
                                        name={"agree"}
                                        id={"agree"}
                                        onBlur={() => handleBlur}
                                        onChange={(checked) => {
                                            console.log("value changed ",checked)
                                            handleChange({
                                                target: {
                                                    name: "agree",
                                                    value: checked,
                                                },
                                            });
                                        }}
                                    />
                                    <p>I agree to the <span onClick={()=>showModal(MODAL_TYPES.TERMS_AND_CONDITION)}>Terms & Conditions</span></p>
                                </div>
                                {errors.mobileNumber && values.mobileNumber.length === CONTENT_DATA.login.maxLength && (
                                    <p className="error">{errors.mobileNumber}</p>
                                )}
                                {
                                    !errors.mobileNumber && !values.agree && touched.agree && (
                                        <p className="error">
                                            *Please agree to the Terms & Conditions
                                        </p>
                                    )
                                }
                                <button type="submit"
                                        disabled={!isValid || !values.agree}>{CONTENT_DATA.login.btnName}</button>
                            </Form>
                        )}
                    </Formik>

                </SecondaryLayout>
            )}
        </>
    );
}

export default Login;
