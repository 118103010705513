import {CONTENT_DATA} from "../../lib/content";
import BookingCard from "../../components/bookingCard/BookingCard";
import React, {useEffect, useState} from "react";
import {ISubscriptionResponse} from "../../lib/customerInterface/interfaces";
import APIS from "../../util/APIUtil";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store/store";
import {ApplicationError} from "../../lib/ApplicationError";
import {APIConstant} from "../../util/APIConstant";
import {StorageManager} from "../../util/DataUtil";
import {resetAllRecord} from "../../store/slice/AuthSlice";
import {showToast} from "../../helper/GlobalToast";
import {NoHistory} from "../../lib/const";
import {updateSubscriptionList} from "../../store/slice/StateUtil";


// import { Plugins } from '@capacitor/core';
const Subscription = () => {
    const dispatch=useDispatch<AppDispatch>();
    const[data,setData]=useState<ISubscriptionResponse[]|undefined>();
    const [loading,isLoading]=useState<boolean>(true)
    const subscriptionResponseList=useSelector((state:RootState)=>state.StateUtil.subscriptionList);


    useEffect(()=>{
if(!data) {
    console.log("data null?")
    if(subscriptionResponseList) {
        console.log("subscription present ")
        isLoading(false)
        setData(subscriptionResponseList)
    }
    else
        getSubscriptionList()
}

    },[])
    useEffect(()=>{
        setData(subscriptionResponseList)
    },[subscriptionResponseList])
useEffect(()=>{
    getSubscriptionList()
},[])
    const getSubscriptionList=()=>{
        console.log("subscription api called ",subscriptionResponseList)
        APIS.getBookingDetail()
            .then(result=>{
                if(result.statusCode===APIConstant.SUCCESS_STATUS_CODE) {
                    setData(result.data);
                    dispatch(updateSubscriptionList(result.data))
                    isLoading(false)
                }
                else if(result.statusCode===APIConstant.FAILURE_STATUS_CODE) {
                    isLoading(false)
                }
            }).catch(err=>{
            if(err instanceof ApplicationError) {
                switch (err.statusCode) {
                    case APIConstant.BAD_REQUEST:
                        showToast(APIConstant.BAD_REQUEST_MESSAGE);
                        break;
                    case APIConstant.INTERNAL_SERVER_ERROR:
                        showToast(APIConstant.INTERNAL_SERVER_ERROR_MESSAGE);
                        break;
                    case  APIConstant.UNAUTHORIZED: {
                        showToast(APIConstant.UNAUTHORIZED_MESSAGE)
                        StorageManager.deleteData("user")
                        dispatch(resetAllRecord(""))
                        break;
                    }
                }


            }}).finally(   ()=>isLoading(false))

    }


const DisplayData=()=>{
    if(!loading) {
        if(data && data.length>0) {
           return <div className="subscription">
                <p className="title">{CONTENT_DATA.subscription.heading}</p>
                {data?.map((data,index) => (
                    <BookingCard key={index}  data={data}/>
                ))}
            </div>
        }
        else {
         return       <div className="history no-history">
                <img src={NoHistory} alt={"no-history"}/>
                <p className="title">No subscription found<br/></p>
                <p></p>
            </div>
        }

    }
    else {
        return <p></p>
    }
}
const handleRefresh = async () => {
        // alert("refreshing")
        isLoading(true);
        // Simulate a network request
    getSubscriptionList();
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                // setItems((prevItems) => ['New Item', ...prevItems]);
                isLoading(false);
                resolve();
            }, 1000);
        });
    };
    return (
        <>
   {/*         <PullToRefresh
                onRefresh={handleRefresh}
                icon={
                    <div className="loader">
                        <ClipLoader  size={40} color={"#F00"} loading={loading} />

                    </div>}
            >


                <DisplayData/>
            </PullToRefresh>*/}
            <DisplayData/>
        </>
    )
}

export default Subscription;