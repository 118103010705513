import * as Yup from "yup";

const LoginValidation = Yup.object().shape({
    mobileNumber: Yup.string()
        .required("Enter mobile number")
        .matches(
            /^[6789][0-9]{9}$/,
            "Invalid Mobile Number  "
        ),
    agree: Yup.boolean()
        .required(
            "Please agree to the Terms & Conditions"
        )
    ,
});
const OtpValidation = Yup.object().shape({
    otp1: Yup.string()
        .required(
            ""
        )
        .matches(
            /^[0-9]{4}$/,
            "Entered invalid OTP"
        ),
    otp2: Yup.string()
        .required(
            ""
        )
        .matches(
            /^[0-9]{4}$/,
            "Entered invalid OTP"
        ),
});

const ProfileUpdateValidation = Yup.object().shape({

    name: Yup.string()
        .required("Enter name")
        .matches(/^[a-zA-Z]{1}[a-zA-Z\s]{2,}$/, "Enter valid name"),
    email: Yup.string()
        .matches(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            "Please enter a valid email"
        )

});
const PincodeValidation = Yup.object().shape({
    pincode: Yup.string()
        .required("")
        .matches(
            /^[a-zA-Z0-9]{1}[a-zA-Z0-9\s]+$/,
            "Invalid Area or Street"
        ),
});

const AddressValidation=Yup.object().shape({
    houseNo:Yup.string().required(""),
    // street:Yup.string().required(""),
    landmark:Yup.string().required(""),
    // pincode:Yup.string().required(""),
    // city:Yup.string().required(""),
    addressName:Yup.string().required(""),
    // area:Yup.string().required(""),

});
const PerDayBookingValidation=Yup.object().shape({
    numberOfBurner:Yup.number().required(""),
    startDate:Yup.string().required(""),
    slot:Yup.object().required(""),
    cuisineType:Yup.string().required(""),
    numberOfPerson:Yup.string().required(""),


});


export {LoginValidation,OtpValidation,ProfileUpdateValidation,PincodeValidation,AddressValidation,PerDayBookingValidation};