import {Preferences} from "@capacitor/preferences";
import NoConnection from "../assets/images/no-internet.png";
export class StorageManager {
    static async set<T>(key: string, newData: T): Promise<void> {
        // Get existing data
        const existingData = await this.get<T>(key);

        if (existingData) {
            // Merge existing data with new data
            const mergedData = { ...existingData, ...newData };

            // Serialize merged data
            const serializedMergedData = JSON.stringify(mergedData);

            // Set the merged data
            await Preferences.set({ key, value: serializedMergedData });
        } else {
            // Serialize new data
            const serializedData = JSON.stringify(newData);

            // Set the new data
            await Preferences.set({ key, value: serializedData });
        }
    }

    static async get<T>(key: string): Promise<T | null> {
        const ret = await Preferences.get({ key });
        console.log("getting ",ret)
        if (!ret || !ret.value) return null;
        return JSON.parse(ret.value) as T;
    }
    static async deleteData(keys:string){
        await Preferences.remove({key: keys})
    }
    static async storageImage() {
        try {

            const response = await fetch(NoConnection);
            const blob = await response.blob(); // Convert response to Blob

            // Step 2: Convert Blob to Base64
            const reader = new FileReader();
            reader.readAsDataURL(blob);

            reader.onload = () => {
                // Step 3: Store the Base64 string in sessionStorage
                const base64Image = reader.result;
                if (typeof base64Image === "string") {
                    sessionStorage.setItem('no_internet', base64Image);
                    console.log("Image stored in sessionStorage");
                } else {
                    console.error('Error converting Blob to Base64: result is not a string');
                }
            };

            reader.onerror = (error) => {
                console.error('Error reading the image:', error);
            };
        } catch (error) {
            console.error('Error fetching or storing image:', error);
        }
    }


    static async getImage() {
        const ret = await sessionStorage.getItem("no_internet");
        console.log("getting ",ret)
        if (!ret ) return undefined;
        return ret;
    }
}

// Usage example

