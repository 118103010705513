import {Outlet} from "react-router-dom";
import BottomMenu from "../../components/bottomMenu/BottomMenu";

const Dashboard = () => {
    return (
        <div className="global-wrapper">
            <Outlet/>
            <BottomMenu/>

        </div>
    )
}
export default Dashboard;