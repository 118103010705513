import './BookingCard.scss';
import {CONTENT_DATA} from "../../lib/content";
import {ISubscriptionResponse} from "../../lib/customerInterface/interfaces";
import React from "react";
import {COMMON_RESPONSE} from "../../lib/const";
import {useDispatch} from "react-redux";
import {AppDispatch, store} from "../../store/store";
import APIS from "../../util/APIUtil";
import {APIConstant} from "../../util/APIConstant";
import {showToast} from "../../helper/GlobalToast";
import {ApplicationError} from "../../lib/ApplicationError";
import {StorageManager} from "../../util/DataUtil";
import {resetAllRecord} from "../../store/slice/AuthSlice";
import {MODAL_TYPES, useGlobalModalContext} from "../../helper/GlobalModal";
import APIUtil from "../../util/APIUtil";
import {updateSubscriptionList} from "../../store/slice/StateUtil";

interface SubscriptionResponse {
    data:ISubscriptionResponse;
}
const getMonthName=(month: number): string=> {
    const months =CONTENT_DATA.months;
    return months[month - 1]; // Adjusting month index to match array index
}
const getDateAndMonth=(startDates: string,endDates: string): { startDate: number, startMonth: string ,endDate: number, endMonth: string }=> {
    console.log(startDates + " lol "+ endDates )
    const startDateParts = startDates.split('-').map(part => parseInt(part, 10));
    const startDate = startDateParts[0];
    const startMonth = getMonthName(startDateParts[1]);
    const endDateParts = endDates.split('-').map(part => parseInt(part, 10));
    const endDate = endDateParts[0];
    const endMonth = getMonthName(endDateParts[1]);

    return { startDate, startMonth,endDate,endMonth };
}
const convertDateAndMonthInWord=(fromDates:string,endDates:string)=>{
 const   { startDate, startMonth  ,endDate , endMonth }=getDateAndMonth(fromDates,endDates);
 return startDate +" "+startMonth +" To " + endDate +" " +endMonth;
}





const BookingCard:React.FC<SubscriptionResponse> = ({data}) => {
    const {showModal} =useGlobalModalContext()
    const dispatch=useDispatch<AppDispatch>();
    //todo cancel subscription
    const cancelSubscription=(bookingId:string)=>{

        APIUtil.cancelSubscription(bookingId)
            .then(response=>{
                if(response.statusCode===APIConstant.SUCCESS_STATUS_CODE) {
                    console.log("success")
                    //     todo filter and remove that specific booking id
                    const filterSubscriptionList1 = filterSubscriptionList(bookingId);
                    if(filterSubscriptionList1){
                        console.log(filterSubscriptionList1)
                    dispatch(updateSubscriptionList(filterSubscriptionList1))}
                    showToast(APIConstant.SUBSCRIPTION_CANCELLED_SUCCESSFULLY)
                }
                if(response.statusCode===APIConstant.FAILURE_STATUS_CODE && response.statusMessage===APIConstant.SUBSCRIPTION_IN_PROGRESS)
                    showToast(APIConstant.SUBSCRIPTION_IN_PROGRESS)

            }).catch(err=> {
            if (err instanceof ApplicationError) {
                switch (err.statusCode) {
                    case APIConstant.BAD_REQUEST:
                        showToast(APIConstant.BAD_REQUEST_MESSAGE);
                        break;
                    case APIConstant.INTERNAL_SERVER_ERROR:
                        showToast(APIConstant.INTERNAL_SERVER_ERROR_MESSAGE);
                        break;
                    case  APIConstant.UNAUTHORIZED: {
                        showToast(APIConstant.UNAUTHORIZED_MESSAGE)
                        StorageManager.deleteData("user")
                        dispatch(resetAllRecord(""))
                        break;
                    }
                }
            }
        })}
    const filterSubscriptionList=(bookingId:string)=>{
        const subscriptionList = store.getState().StateUtil.subscriptionList;
        if(subscriptionList)
            return subscriptionList.filter(subscriptionResponse=>subscriptionResponse.bookingId!==bookingId);

    }
    return (
        <div className="booking-card">
            <section className="heading">
                <p className="bold">{data.subscriptionType.toUpperCase()} ({convertDateAndMonthInWord(data.serviceStartDate,data.serviceEndDate)}) </p>
                <p className="normal">{data.daysLeft}{CONTENT_DATA.subscription.daysLeft}</p>
            </section>
            <section className="booking-card-details">
                {/*todo add cancel subscription*/}

                <section className="cancel-card-detail">
                    <div className="confirm-bottom">
                        <p className="normal-text">{CONTENT_DATA.history.bookingId}{data.bookingId}</p>
                        {data.cancelable &&(
                            //todo getting bookingid from here
                        <p className="cancel-button" onClick={()=>
                            showModal(MODAL_TYPES.CANCEL_SUBSCRIPTION, {bookingId:data.bookingId})
                        }>Cancel</p>
                        )}
                    </div>
                </section>
                {/*</div>*/}
                <p className="normal-text bold">{data.area} | {data.serviceStartDate}</p>
                <p className="normal-text green">{CONTENT_DATA.subscription.actualAmount}{data.actualAmount}</p>
                {data.couponApplied &&(
                    <>
                <p className="normal-text green">{CONTENT_DATA.subscription.couponSaved}{data.discountPrice}({data.couponCode})</p>
                <p className="normal-text green">{CONTENT_DATA.subscription.amount}{data.amountPaid}</p>
                    </>
            )}
                <div className="oth-content">
                    <p className="normal-text with-border">{data.subscriptionType}</p>
                    {data.slots.map((value, index) => (
                        <p key={index} className="normal-text with-border">{value}</p>
                    ))}
                    <p className="normal-text with-border">{data.numberOfPerson}{CONTENT_DATA.history.numberOfPerson}</p>
                </div>
            </section>
            {data.bookingDetail &&(
            data.bookingDetail.map(((value, index) => (
                (value.status === COMMON_RESPONSE.IN_PROGRESS || value.status===COMMON_RESPONSE.CONFIRM) ? (
                    <section className="confirm-card" key={index}>
                        <div className="confirm-top">
                            <section className="left-wrapper">
                                {/*todo add slot */}
                                <p className="confirm-bold">{value.slotTime}</p>
                            </section>
                            <p className="status-details">{value.status}</p>
                        </div>
                        <div className="divider"></div>
                        <div className="confirm-bottom">
                            <p className="chef-name">{value.chefDetail?.name}</p>
                            <p className="otp-details">{CONTENT_DATA.subscription.otp}{value.otp}</p>
                        </div>
                    </section>
                ) : (
                    <section className="pending-card" key={index}>
                        <p className="slot">{value.slotTime}</p>
                        <p className="pending-status">{value.status}</p>

                    </section>
                )
            ))))}

        </div>
    )
}
export default BookingCard;