import {combineReducers, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IOtpValidateResponse, IUserDetail, UserDetailResponse} from "../../lib/customerInterface/interfaces";

interface authSliceInterface {
    deviceId: string,
    token: string,
    customerId: string,
    isProfileUpdated: boolean,
    slots: string[],
    isClear: boolean,
    mobileNumber: string,
    name: string,
    email: string

}


const authSliceInitialState: authSliceInterface = {
    deviceId: "",
    token: "",
    customerId: "",
    slots: [],
    isProfileUpdated: false,
    isClear: false,
    mobileNumber: '',
    name: '',
    email: ''
    // eslint-disable-next-line react-hooks/rules-of-hooks

}


const authSlice = createSlice({
    name: "authDetails",
    initialState: authSliceInitialState,

    reducers: {
        updateChefDetails: (state, action: PayloadAction<IUserDetail>) => {
            console.log("kola user response once true ", action.payload)
            state.deviceId = action.payload.deviceId;
            state.token = action.payload.token;
            state.customerId = action.payload.customerId;
            state.isProfileUpdated = action.payload.isProfileUpdated;
            state.mobileNumber = action.payload.mobileNumber;
            state.email = action.payload.email;
            state.name = action.payload.name;
            console.log("sola user response once true ", state)
        },
        updateUserDetailResponseWhenTrue: (state, action: PayloadAction<UserDetailResponse>) => {
            console.log("updared user response once true ", action.payload)
            state.isProfileUpdated = action.payload.isProfileUpdated;
            state.name = action.payload.name;
            state.email = action.payload.email
            state.mobileNumber = action.payload.mobileNumber
            console.log("user details are ", state)
        },
        sendUserDetails: (state, action: PayloadAction<string>) => {
            state.token = action.payload;
            state.deviceId = action.payload;
            state.customerId = action.payload;
        },
        updateProfileStatus: (state, action: PayloadAction<boolean>) => {

            state.isProfileUpdated = action.payload;

        }, sliceDetails: (state, action: PayloadAction<string[]>) => {

            state.slots = action.payload;

        },
        resetAllRecord: (state, action: PayloadAction<string>) => {
            // state.deviceId = "";
            // state.token = "";
            // state.customerId = "";
            // state.isProfileUpdated=false;
            //     state.isClear=true;
            return authSliceInitialState;
        },
        mobileNumberDetails: (state, action: PayloadAction<string>) => {
            state.mobileNumber = action.payload;
        }

    }
})



export const {
    resetAllRecord, updateChefDetails, sendUserDetails, sliceDetails
    , updateUserDetailResponseWhenTrue, updateProfileStatus, mobileNumberDetails
} = authSlice.actions;

// const COYDReducer = combineReducers({
//     authSlice: authSlice.reducer,
// })


export default authSlice.reducer;