import {
    CFEnvironment,
    CFPaymentGateway,
    CFSession,
    CFUPIIntentCheckoutPayment
} from "@awesome-cordova-plugins/cashfree-pg";
import {updatePaymentStatus} from "../../store/slice/StateUtil";
import {CONTENT_DATA} from "../../lib/content";
import APIS from "../../util/APIUtil";
import {APIConstant} from "../../util/APIConstant";



const checkStatusAndSetStatus=(orderId:string,customerId:string,token:string,dispatch:any)=>{
    APIS.validateBooking(customerId,orderId).then(response=>{
        if(response.statusCode===APIConstant.SUCCESS_STATUS_CODE)
            dispatch(updatePaymentStatus(CONTENT_DATA.payment.SUCCESS))
        if(response.statusCode===APIConstant.FAILURE_STATUS_CODE)
            dispatch(updatePaymentStatus(CONTENT_DATA.payment.FAILURE))
    })


}
export const payment=(paymentSession:string,orderIds:string,customerId:string,token:string,dispatch:any)=>{

    console.log("payment called called")
    const callbacks = {

        onVerify: function (result:any) {
            let details = {
                "orderID": result.orderID
            }
            console.log(details);
            if(result) {
                checkStatusAndSetStatus(orderIds,customerId,token,dispatch)

            }

        },
        onError: function (error:any){
            let errorObj = {
                "orderID": orderIds,
                "status": error.status,
                "code": error.code,
                "type": error.type,
                "message": error.message
            }
            console.log(errorObj);
            if(errorObj) {
                checkStatusAndSetStatus(orderIds,customerId,token,dispatch)
            }

        }
    }
    CFPaymentGateway.setCallback(callbacks)


    try {
        const session:CFSession={
            payment_session_id:paymentSession,
            orderID:orderIds,
            environment:CFEnvironment.PRODUCTION,
        }


        const obj= new CFUPIIntentCheckoutPayment(session, null);
        CFPaymentGateway.doUPIPayment(obj);

        return true;
    }
    catch (e){alert(e)}

}


