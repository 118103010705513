import { Navigate, useNavigate } from "react-router-dom";
import { ROUTES } from "../lib/const";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store/store";
import { resetStateUtilData } from "../store/slice/StateUtil";
import { resetUserSlice } from "../store/slice/UserSlice";


const PrivateRoute = ({ children }: { children: JSX.Element }) => {
    const accessDetails = useSelector((state: RootState) => state.authSlice.token);
    useSelector((state: RootState) => state.authSlice.isClear);
    const dispatch = useDispatch<AppDispatch>();



    if(!!accessDetails) {
        return children;
    }
    else  {
        dispatch(resetStateUtilData(""));
        dispatch(resetUserSlice(""));
        return <Navigate to={ROUTES.STATIC_PAGES} />
    }

    // return !!accessDetails ? children : <Navigate to={ROUTES.STATIC_PAGES} />;
};

export default PrivateRoute;
