import {CurrentBooking, IServiceState, ISlot} from "../StateUtil";
import {IServiceResponse} from "../../../lib/dto/IServiceResponse";
import {CouponResponse} from "../../../lib/customerInterface/interfaces";

export const resetActiveBooking=()=>{
    const current: CurrentBooking = {
        startDate: '',
        numberOfPerson: 0,
        subscriptionType: '',
        numberOfBurner: '',
        slot: [],
        cuisineType: '',
        chefPreferred: '',
        pincode:''
    }
    return current;
}
export const resetServiceState=()=>{
    const serviceState:IServiceState= {
        noService: false,
        serviceNotAvailable: false
    }
    return serviceState;
}
export const resetSlotState=()=>{
    const slotState:ISlot={
        slots:[]
    }
    return slotState;
}
export const resetActiveSlotState=()=>{
    const state:string[]=[]
return state;
}
export const resetServiceDetailState=()=>{
    const serviceState:IServiceResponse={
        areaList: [],
        pricePerMin: 0,
        cuisineType: [],
        contactNumber:'',
        details: {
            pincode: '',
            services: []
        }
    }
    return serviceState;
}


export const resetPaidState=()=>{
    return "";
}
export const resetActiveCouponState=()=>{
    return undefined;
}
export const resetCouponState=()=>{
    const state:CouponResponse[]=[]
    return state;
}
export const resetSubscriptionList=()=>{
    return undefined;
}
export const resetServiceDetail=()=>{
    const state:IServiceResponse= {
        areaList: [],
            pricePerMin: 0,
            contactNumber:'',
            cuisineType: [],
            details: {
            pincode: '',
                services: []
        }
    }
    return state;
}

