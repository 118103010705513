import './Popups.scss';
import Popup from "../../helper/Popup";
import React, {useEffect, useState} from "react";
import {ReactComponent as Add} from "../../assets/images/add.svg";
import {MODAL_TYPES, useGlobalModalContext} from "../../helper/GlobalModal";
import AddressDetails from "../addressDetails/AddressDetails";
import {CONTENT_DATA} from "../../lib/content";
import {AppDispatch, RootState} from "../../store/store";
import {useDispatch, useSelector} from "react-redux";
import {AddressDto} from "../../lib/dto/AddressDto";
import {updateActiveAddress} from "../../store/slice/UserSlice";
import APIS from "../../util/APIUtil";

import {APIConstant, APIEndpoint} from "../../util/APIConstant";
import {updateActiveSlot, updateAutoAddressAndPincode, updateServiceDetail} from "../../store/slice/StateUtil";
import {ApplicationError} from "../../lib/ApplicationError";
import {showToast} from "../../helper/GlobalToast";
import {StorageManager} from "../../util/DataUtil";
import {resetAllRecord} from "../../store/slice/AuthSlice";
import {ROUTES, TOAST_MESSAGE} from "../../lib/const";
import {useNavigate} from "react-router-dom";

const SelectAddress = () => {
const navigate=useNavigate();
    const {showModal,hideModal} = useGlobalModalContext();
    const [checkedAddressId, setCheckedAddressId] = useState<string | null>(null);
    const addressDto=useSelector((state:RootState)=>state.UserSlice.address)
    const activeAddress:AddressDto = useSelector((state: RootState) => state.UserSlice.activeAddress);
const dispatch=useDispatch<AppDispatch>();
    useSelector((state: RootState) => state.authSlice.token);
    const serviceDetail = useSelector((state: RootState) => state.StateUtil.serviceDetail)
    const handleCheck = (addressId: string,address:AddressDto) => {
        setCheckedAddressId(addressId === activeAddress.addressId ? null : addressId);

        console.log("address from activ address ",activeAddress)
        console.log("address from slect address ",address)
        console.log("address from service address ",serviceDetail.details.pincode)
        console.log(checkedAddressId)
        dispatch(updateActiveAddress(address))
        hideModal();
        //todo not required
     /*   if(address.pincode!==activeAddress.pincode) {

           APIS.getServiceDetail(address.pincode).then(response => {

                if (response.statusCode === APIConstant.SUCCESS_STATUS_CODE) {
                    console.log("updating service detail")
                    dispatch(updateServiceDetail(response.data))
                    dispatch(updateActiveAddress(address))
                     dispatch(updateActiveSlot([]))
                    hideModal();

                }
                else  {
                    showToast(`${TOAST_MESSAGE.TEMPORARILY_SERVICE_NOT_AVAILABLE_AT} ${address.pincode}` )
                    // todo autocomplete will come here
                    // dispatch(updateAutoAddressAndPincode(address.pincode))
                }

            }).catch(err => {
                if (err instanceof ApplicationError) {
                    switch (err.statusCode) {
                        case APIConstant.BAD_REQUEST:
                            showToast(APIConstant.BAD_REQUEST_MESSAGE);
                            break;
                        case APIConstant.INTERNAL_SERVER_ERROR:
                            showToast(APIConstant.INTERNAL_SERVER_ERROR_MESSAGE);
                            break;
                        case  APIConstant.UNAUTHORIZED: {
                            showToast(APIConstant.UNAUTHORIZED_MESSAGE)
                            StorageManager.deleteData("user")
                            dispatch(resetAllRecord(""))
                            break;
                        }
                    }


                }
            });
        }
        else {
            dispatch(updateActiveAddress(address))
            hideModal();
        }*/
        // dispatch(updateActiveAddress(address))


    };
    useEffect(()=>{
       setCheckedAddressId(activeAddress.addressId);
    },[checkedAddressId])
    return (
        <Popup modalBgClass="address-popup">
            <p className="title self-center">{CONTENT_DATA.selectAddressHeading}</p>
            <div className="Selected-address-wrapper">
                {

                    addressDto.map((addressDetail)=>(
                        <AddressDetails
                            key={addressDetail.addressId}
                            checked={addressDetail.addressId === checkedAddressId}
                            handleCheck={() => handleCheck(addressDetail.addressId,addressDetail)}
                            name={addressDetail.addressName}
                            address={`${addressDetail.flatNo},${addressDetail.subAddress}, ${addressDetail.mainAddress}`}
                            isButton={false}
                        />
                    ))
                }
            </div>
            <p className="large-font" onClick={() => {
            hideModal();
                // showModal(MODAL_TYPES.ADDRESS)
                navigate(ROUTES.ADD_ADDRESS);
            }}><Add/> Add Address</p>
        </Popup>
    )
}

export default SelectAddress;

