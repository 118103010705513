export const APIConstant={

     SUCCESS_STATUS_CODE:1000,
     FAILURE_STATUS_CODE:900,
     SERVICE_NOT_AVAILABLE:"SERVICE NOT AVAILABLE",
     NO_SLOT_AVAILABLE:"NO SLOT AVAILABLE",
     SUCCESS:"success",
     ADDRESS_UPDATE_SUCCESS:"address updated Successfully",
     NOT_ACCEPTING_ANY_FURTHER_REQUEST:"NOT ACCEPTING ANY FURTHER REQUEST",
     UNAUTHORIZED:401,
     UNAUTHORIZED_MESSAGE:'Session Expired !!',
     NETWORK_ERROR_MESSAGE:'Error while connecting to coyd !!',
     NETWORK_ERROR:50000,
     BAD_REQUEST:400,
     BAD_REQUEST_MESSAGE:"Bad Request Message",
     INTERNAL_SERVER_ERROR:500,
     INTERNAL_SERVER_ERROR_MESSAGE:"Something went wrong try again later !!",
     ADDRESS_CONTAIN_BOOKING:'address contain booking',
     ADDRESS_NOT_FOUND:'no address found',
     SUBSCRIPTION_CANCELLED_SUCCESSFULLY:'Subscription cancelled successfully',
     SUBSCRIPTION_IN_PROGRESS:"can't cancel subscription in progress!!"


}


export const APIEndpoint={
     REGISTER:"customer/register",
     RESEND_OTP:"customer/resend/otp",
     VALIDATE_OTP:"customer/validate/otp",
     UPDATE_PROFILE:"customer/update",
     CUSTOMER_DETAIL:"customer/detail",
     GET_SLOT:"service/slots",
     SERVICE:"service/location/serviceable",
     GET_ADDRESS:"address/all",
     ADD_ADDRESS:"address",
     DELETE_ADDRESS:'address/delete',
     INITIATE_BOOKING:"booking/initiate",
     CANCEL_SUBSCRIPTION:"booking/cancel",
     VERIFY_PAYMENT:"payment/status",
     SERVICE_REACHABLE:'service/location/reachable',
     AUTO_COMPLETE:'service/location/serviceable/autoComplete',
     BY_ADDRESS:'service/location/serviceable/byaddress',
     BOOKING_DETAIL:"booking/detail/all",
     BOOKING_HISTORY:"booking/history",
     COUPON:'coupon/'

}