import React, { useState, createContext, useContext } from "react";
import popups from "../components/popups/Popups";

export const MODAL_TYPES = {
  PINCODE: "PINCODE",
  SLOT:"SLOT",
  ADDRESS:"ADDRESS",
  SELECT_ADDRESS:"SELECT_ADDRESS",
  EDIT_ADDRESS:"EDIT_ADDRESS",
  EDIT_PINCODE:"EDIT_PINCODE",
  CONTACT_US:"CONTACT_US",
  TERMS_AND_CONDITION:"TERMS_AND_CONDITION",
  CANCEL_SUBSCRIPTION:"CANCEL SUBSCRIPTION"

};

const MODAL_COMPONENTS: any = {
  [MODAL_TYPES.PINCODE]: popups.PinCode,
  [MODAL_TYPES.SLOT]: popups.Slot,

  [MODAL_TYPES.SELECT_ADDRESS]: popups.SelectAddress,
  [MODAL_TYPES.EDIT_ADDRESS]: popups.EditAddress,
  [MODAL_TYPES.EDIT_PINCODE]: popups.EditPincode,
  [MODAL_TYPES.CONTACT_US]:popups.ContactUs,
  [MODAL_TYPES.TERMS_AND_CONDITION]:popups.TermsPopup,
  [MODAL_TYPES.CANCEL_SUBSCRIPTION]:popups.CancelSubscription,

};

type ContextType = {
  showModal: (
    modalType: string,
    modalProps?: any,
    onClose?: () => void,
  ) => void;
  hideModal: (blockOnClose?: boolean) => void;
  store: any;
};

const initialState: ContextType = {
  showModal: () => {},
  hideModal: (blockOnClose?: boolean,modalType?:string) => {},
  store: {},
};

const GlobalModalContext = createContext(initialState);
export const useGlobalModalContext = () => useContext(GlobalModalContext);

interface GlobalModalStore {
  modalType: string;
  modalProps: any;
  onClose: () => void;

}
export const GlobalModal: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [store, setStore] = useState<GlobalModalStore>({
    modalType: "",
    modalProps: {},
    onClose: () => {},
  });
  const { modalType, modalProps } = store || {};

  const showModal = (
    modalType: string,
    modalProps: any = {},
    onClose: () => void = () => {}
  ) => {
    setStore({
      ...store,
      modalType,
      modalProps,
      onClose,
    });
  };

  const hideModal = (blockOnClose?: boolean,modalType?:string) => {
    setStore({
      ...store,
      modalType: "",
      modalProps: {},
      onClose: () => {},
    });
    if (blockOnClose !== true) {
      store.onClose();
    }

  };

  const renderComponent = () => {
    const ModalComponent = MODAL_COMPONENTS[modalType];
    if (!modalType || !ModalComponent) {
      return null;
    }
    return (
      <ModalComponent id="global-modal" hideModal={hideModal} {...modalProps} />
    );
  };

  return (
    <GlobalModalContext.Provider value={{ store, showModal, hideModal }}>
      {renderComponent()}
      {children}
    </GlobalModalContext.Provider>
  );
};
