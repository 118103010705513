


import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/global.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react'; // Import PersistGate
import { store, persistor } from "./store/store";
import { GlobalLoader } from "./helper/GlobalLoader";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <Provider store={store}>
        {/* Wrap your App component with PersistGate */}
        <PersistGate loading={null} persistor={persistor}>
            <GlobalLoader>

                <App />
            </GlobalLoader>
        </PersistGate>
    </Provider>
);

reportWebVitals();
