import './Dashboard.scss';
import {BackArrow, COMMON_RESPONSE, ROUTES} from "../../lib/const";
import React, {useEffect, useState} from "react";
import {CONTENT_DATA} from "../../lib/content";
import {ReactComponent as Edit} from "../../assets/images/edit.svg";
import {
    clearCurrentBooking,
    CurrentBooking, updateActiveCoupon,
    updateActiveSlot,

    updatePaymentStatus,

} from "../../store/slice/StateUtil";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store/store";
import {AddressDto} from "../../lib/dto/AddressDto";
import {useNavigate} from "react-router-dom";
import {BookingRequestDto} from "../../lib/dto/BookingDto";
import APIS from "../../util/APIUtil";
import {APIConstant} from "../../util/APIConstant";
import {payment} from "../staticPages/Payment";
import {showToast} from "../../helper/GlobalToast";
import {ApplicationError} from "../../lib/ApplicationError";
import {StorageManager} from "../../util/DataUtil";
import {resetAllRecord} from "../../store/slice/AuthSlice";
import {CouponResponse} from "../../lib/customerInterface/interfaces";

const BookingPreview = () => {
    const activeBooking: CurrentBooking = useSelector((state: RootState) => state.StateUtil.booking);
    const activeAddress: AddressDto = useSelector((state: RootState) => state.UserSlice.activeAddress);
    const subscriptionDetail = useSelector((state: RootState) => state.StateUtil.serviceDetail);
    const customerId = useSelector((state: RootState) => state.authSlice.customerId);
    const pincode = useSelector((state: RootState) => state.StateUtil.autoAddress.pincode);
    const paymentStatus = useSelector((state: RootState) => state.StateUtil.paid);
    const token = useSelector((state: RootState) => state.authSlice.token);
    const coupons = useSelector((state: RootState) => state.StateUtil.coupons)
    const activeCoupon = useSelector((state: RootState) => state.StateUtil.activeCoupon);
    const [price, setPrice] = useState<number>(0);
    const [pricePerMin, setPricePerMin] = useState<number>(0);
    const [oneTimeSubscription, setsOneTimeSubscription] = useState<boolean>(false);
    const navigate = useNavigate();
    const [couponCode, setCouponCode] = useState<CouponResponse|undefined>(undefined);
    const [couponApplied, setCouponApplied] = useState<boolean>(false);
    const [discountPrice, setDiscountPrice] = useState<number>(0);

    const handleApplyCoupon = () => {
        console.log("handle coupon ",activeCoupon)

        if (couponCode) {
            if (couponCode.discountType === "PERCENTAGE") {
                console.log("price is ",price)
                const discountPrice = Math.round(price-(price * (couponCode.discount / 100)));
                const finalPrice=price-discountPrice;
                console.log("precentage ", discountPrice)
                setCouponCode(couponCode);
                setDiscountPrice(discountPrice);
                setCouponApplied(true)
            }
            if (couponCode.discountType === "AMOUNT") {
                console.log("price is ",price)
                const discountPrice = Math.round(price-couponCode.discount);

                console.log("precentage ", discountPrice)
                setCouponCode(couponCode);
                setDiscountPrice(discountPrice);
                setCouponApplied(true)
            }

        }
        // setCouponCode(couponCode);
    };

    const handleRemoveCoupon = () => {

        setCouponCode(undefined);
        setDiscountPrice(0);
        setCouponApplied(false);
        dispatch(updateActiveCoupon(undefined))


        // setCouponCode(couponCode);
    };
    useEffect(()=>{
        if(activeCoupon ) {
            setCouponCode(activeCoupon)
            setCouponApplied(true)
            handleApplyCoupon();
        }
        else {
            setCoupon();
        }
    },[couponCode])


    const dispatch = useDispatch<AppDispatch>();
    console.log("payment status is ", paymentStatus)
    const findTotalAmount = (subscriptionType: string, slotType: string, numberOfPerson: number) => {

        console.log("preview called")
        const oneTimeOrDayService = subscriptionDetail.details.services.find(service => service.type === subscriptionType);
        console.log(oneTimeOrDayService)
        if (oneTimeOrDayService) {

            //todo Find the slot with type "ONE TIME" within the "WEEKLY" service
            const oneTimeSlot = oneTimeOrDayService.slot.find(slot => slot.type === slotType);
            console.log(oneTimeSlot)
            if (oneTimeSlot) {
                //todo Find the person with number of persons as 2 within the "ONE TIME" slot
                const person = oneTimeSlot.persons.person.find(person => person.numberOfPerson === numberOfPerson);
                console.log(person)
                if (person) {
                    //todo Extract and log the price
                    const price = person.basePrice;
                    setPrice(price);
                    console.log("Price for 2 persons in the weekly slot (ONE TIME):", price);
                }
            }
        }
    }
    const getSubscriptionType= (slot: string[], subscriptionType: undefined|string)=>{

        const serviceTypeMap = COMMON_RESPONSE.map;
        const slotType = slot.length > 1 ? "TWO TIME" : "ONE TIME"
        if (subscriptionType === undefined) {
            const serviceType = slot.length > 1 ? serviceTypeMap?.get("ONE DAY") : serviceTypeMap?.get("ONE TIME")
            return serviceType;
        }
        else {
            return  subscriptionType;
        }
    }
    const mapPaymentAmount = () => {
        const {subscriptionType, numberOfPerson, slot} = activeBooking
        const serviceTypeMap = COMMON_RESPONSE.map;
        const slotType = slot.length > 1 ? "TWO TIME" : "ONE TIME"
        if (subscriptionType === undefined) {
            // const serviceType = slot.length > 1 ? serviceTypeMap?.get("ONE DAY") : serviceTypeMap?.get("ONE TIME")
            const serviceType = getSubscriptionType(slot,subscriptionType)
            if (serviceType != null) {
                setPricePerMin(subscriptionDetail.pricePerMin);
                setsOneTimeSubscription(true);
                findTotalAmount(serviceType, slotType, numberOfPerson)
            }
        } else {

            const subscriptionTypes = serviceTypeMap?.get(subscriptionType);
            if (subscriptionTypes != null) {
                findTotalAmount(subscriptionTypes, slotType, numberOfPerson)
            }
        }
    }

    const updateState = () => {
        dispatch(updatePaymentStatus(""));
        dispatch(updateActiveSlot([]));
        dispatch(clearCurrentBooking())
    }
    const setCoupon=()=>{
        const subscriptionType = getSubscriptionType(activeBooking.slot,activeBooking.subscriptionType);
        console.log("set coupon subscription ",subscriptionType)
        if(subscriptionType) {
            if(subscriptionType==="ONE_TIME" || subscriptionType==="ONE_DAY") {
                const cleanedSubscriptionType = subscriptionType.replace(/_/g, '');
                console.log("clear coupon ",cleanedSubscriptionType)
                const coupon = coupons.find(coupon => coupon.category === cleanedSubscriptionType);
                console.log("set coupon ",coupon)
                if (coupon)
                    setCouponCode(coupon)

            }
            else {
                const cleanedSubscriptionType = subscriptionType.replace(/ /g, '_');
                const coupon = coupons.find(coupon => coupon.category === cleanedSubscriptionType);
                console.log("set else coupon ",coupon)
                if (coupon)
                    setCouponCode(coupon)
            }
        }
    }

    useEffect(() => {
        mapPaymentAmount()
    }, [])
    useEffect(() => {
        if (paymentStatus === CONTENT_DATA.payment.SUCCESS) {
            updateState();
            navigate(ROUTES.PAYMENT_SUCCESS)
        }
        if (paymentStatus === CONTENT_DATA.payment.FAILURE) {
            updateState()
            navigate(ROUTES.PAYMENT_FAILURE)
        }
    }, [paymentStatus])

    const checkout = () => {

        const data: BookingRequestDto = {

            burnerType: activeBooking.numberOfBurner,
            cuisineType: activeBooking.cuisineType,
            numberOfCustomer: activeBooking.numberOfPerson,
            slot: activeBooking.slot,
            price: price,
            addressId: activeAddress.addressId,
            startDate: activeBooking.startDate,
            endDate: '',
            serviceType: !activeBooking.subscriptionType ? '' : activeBooking.subscriptionType,
            chefPreferred: '',
            pincode: pincode,
            discountPrice:discountPrice?discountPrice:0,
            couponCode:couponCode?couponCode.code:''


        }
        APIS.initiateBooking(data)
            .then(response => {
                if (response.statusCode === APIConstant.SUCCESS_STATUS_CODE) {
                    const {orderId, sessionId} = response.data;
                    showToast("initiating payment")

                    payment(sessionId, orderId, customerId, token, dispatch);

                }
                else if(response.statusCode===APIConstant.FAILURE_STATUS_CODE ) {
                    if(response.statusMessage===APIConstant.SERVICE_NOT_AVAILABLE)
                        showToast(APIConstant.SERVICE_NOT_AVAILABLE)
                    if(response.statusMessage===APIConstant.NOT_ACCEPTING_ANY_FURTHER_REQUEST)
                        showToast(APIConstant.NOT_ACCEPTING_ANY_FURTHER_REQUEST)

                }
            }).catch(err => {
            if (err instanceof ApplicationError) {
                switch (err.statusCode) {
                    case APIConstant.BAD_REQUEST:
                        showToast(APIConstant.BAD_REQUEST_MESSAGE);
                        break;
                    case APIConstant.INTERNAL_SERVER_ERROR:
                        showToast(APIConstant.INTERNAL_SERVER_ERROR_MESSAGE);
                        break;
                    case  APIConstant.UNAUTHORIZED: {
                        showToast(APIConstant.UNAUTHORIZED_MESSAGE)
                        StorageManager.deleteData("user")
                        dispatch(resetAllRecord(""))
                        break;
                    }
                }
            }
        })
    }
    console.log("coupon code is ", coupons)
    return (
        <div className="booking-page-wrapper">
            <div className="booking-page">
                <img className="back-arrow" src={BackArrow} alt="BackArrow" onClick={() => {
                    navigate(-1)

                }}/>
                <p className="title">{CONTENT_DATA.bookingPreview.heading}</p>
                <div className="preview-details">
                    <p className="preview-details-edit"><Edit/>{CONTENT_DATA.bookingPreview.editDetails}</p>
                    <p className="preview-details-heading">{CONTENT_DATA.bookingPreview.FOOD_DETAIL}</p>
                    <p className="preview-details-sub">{CONTENT_DATA.bookingPreview.cuisineType} {activeBooking.cuisineType}</p>
                    <p className="preview-details-heading">{CONTENT_DATA.bookingPreview.OTHER_DETAIL}</p>
                    <p className="preview-details-sub">{CONTENT_DATA.bookingPreview.people} {activeBooking.numberOfPerson}</p>
                    <p className="preview-details-heading">{CONTENT_DATA.bookingPreview.SLOT_DETAIL}</p>
                    <p className="preview-details-sub">{CONTENT_DATA.bookingPreview.subscriptionType} {!activeBooking.subscriptionType ? activeBooking.slot.length === 1 ? "ONE TIME" : "ONE DAY" : activeBooking.subscriptionType}</p>
                    <p className="preview-details-sub">{CONTENT_DATA.bookingPreview.startDate} {activeBooking.startDate}</p>
                    <p className="preview-details-sub">{CONTENT_DATA.bookingPreview.slot1} {activeBooking.slot[0]}</p>
                    <p className="preview-details-sub">{activeBooking.slot.length > 1 ? CONTENT_DATA.bookingPreview.slot2.concat(activeBooking.slot[1]) : ''} </p>
                    <p className="preview-details-heading">Address:</p>
                    <div className="address-preview">
                        <p className="name-preview">{activeAddress.addressName}
                        </p>
                        <p className="preview-details-sub a-d">{`${activeAddress.flatNo} , ${activeAddress.subAddress},${activeAddress.mainAddress}`}</p>
                    </div>
                    {coupons.length>0 && couponCode &&(
                        <div className="apply-coupon">

                            <p className="coupons-heading">Offers and Coupons:</p>
                            <div className={"coupon-container prview-page"} >
                                {/*<div className={"coupon-text-container"}>*/}
                                {couponCode && !couponApplied &&(
                                    <>
                                        <div className={"active-coupon"}>

                                            <p key={"coupon"} className="coupon-value">{couponCode.code}</p>
                                            <p className="coupon-btn" onClick={()=>handleApplyCoupon()}>APPLY</p>

                                        </div>

                                        <div className="divider"></div>
                                        <div className="add-coupon" onClick={()=>navigate(ROUTES.COUPON)}>
                                            <p>{`view more offers  `}<span>&#8594;</span></p>
                                        </div>
                                    </>
                                ) }
                                {couponCode && couponApplied && (
                                    <div className={"active-coupon"}>

                                        <p key={"coupon"} className="coupon-value">{couponCode.code}</p>
                                        <p className="coupon-btn" onClick={()=>handleRemoveCoupon()}>Remove</p>

                                    </div>
                                )}

                            </div>

                        </div>
                    )}

                    {oneTimeSubscription && (
                        <p className="preview-details-sub otc">* <span>₹ {`${subscriptionDetail.pricePerMin}`}/-</span> will be charged per
                            min during cooking</p>
                    )}
                </div>
                <div className="preview-btn-wrapper">

                    {couponApplied ?
                        <div className="left">

                            <del className="amount">&#8377; {price}/-</del>
                            <p className="amount">&#8377; {discountPrice}/-</p>
                            <p className="tax">{CONTENT_DATA.bookingPreview.tax}</p>
                        </div>

                        :
                        <div className="left">
                            <p className="amount">&#8377; {price}/-</p><p
                            className="tax">{CONTENT_DATA.bookingPreview.tax}</p>

                        </div>
                    }
                    <button className="" type="submit"
                            onClick={() => checkout()}>{CONTENT_DATA.bookingPreview.proceedToPayBtn}</button>

                </div>
            </div>
        </div>
    )
}
export default BookingPreview;