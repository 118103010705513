import './Dashboard.scss';
import {BackArrow, COMMON_RESPONSE, ROUTES} from "../../lib/const";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store/store";
import {CouponResponse} from "../../lib/customerInterface/interfaces";
import {CurrentBooking, updateActiveCoupon} from "../../store/slice/StateUtil";

const ApplyCoupon = () => {
    const navigate = useNavigate();
    const coupons = useSelector((state: RootState) => state.StateUtil.coupons);
    const dispatch=useDispatch<AppDispatch>();
    const activeBooking: CurrentBooking = useSelector((state: RootState) => state.StateUtil.booking);
    const[subscriptionType,setSubscriptionType]=useState<string>('');
    const goBack = () => {
        navigate(-1)
    }
    const handleApplyCoupon = (coupon:CouponResponse) => {
     dispatch(updateActiveCoupon(coupon))
goBack();
            }
    const getSubscriptionType= ()=>{
        const {subscriptionType, numberOfPerson, slot} = activeBooking
        const serviceTypeMap = COMMON_RESPONSE.map;
        const slotType = slot.length > 1 ? "TWO TIME" : "ONE TIME"
        if (subscriptionType === undefined) {
            const serviceType = slot.length > 1 ? "ONEDAY" : "ONETIME";
           setSubscriptionType(serviceType);
        }
        else {
            setSubscriptionType(subscriptionType);
        }
    }
    useEffect(()=>{
        getSubscriptionType();
    },[subscriptionType])

        // setCouponCode(couponCode);

    return (
        <div className="apply-coupon">
            <img className="back-arrow" src={BackArrow} alt="BackArrow" onClick={goBack}/>
            <p className="title">Apply Coupons</p>
            <div className="search-sec">
                <input type={"text"} />
                <button type="button">Apply</button>
            </div>
            <p className="coupons-heading">More offers</p>
            {coupons.map(((value, index) => (
                <div className={"coupon-container"} style={{display:'flex',flexDirection:'column'}}>
                    {/*<div className={"coupon-text-container"}>*/}
                        <div className={"active-coupon"}>
                            <p key={index} className="coupon-value">{value.code}</p>
                            {value.category===subscriptionType ?
                            <p className="coupon-btn" onClick={()=>handleApplyCoupon(value)} >APPLY</p>
                            :
                                <p className="coupon-btn disable" onClick={()=>handleApplyCoupon(value)} >Not Applicable</p>
                            }
                        </div>
                        <div className="divider"></div>
                        <div className="add-coupon">
                            <p>{value.description}</p>
                        </div>
                        {/*<div>*/}
                        {/*    <p>{value.discountType}</p>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                </div>
            )))}
        </div>
    )
}
export default ApplyCoupon;