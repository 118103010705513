import './Dashboard.scss'
import {CARD_TYPE, HomeImg, ROUTES} from "../../lib/const";
import SubscriptionCard from "../../components/subscriptionCard/SubscriptionCard";
import Header from "../../components/header/Header";
import {CONTENT_DATA} from "../../lib/content";
import {Outlet, useNavigate} from "react-router-dom";
import Slider from "../../components/slider/Slider";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store/store";
import {useEffect, useState} from "react";
import {MODAL_TYPES, useGlobalModalContext} from "../../helper/GlobalModal";
import NotServicable from "../staticPages/error/NotServicable";
import NoService from "../staticPages/error/NoService";
import APIS from "../../util/APIUtil";
import {APIConstant, APIEndpoint} from "../../util/APIConstant";
import {CommonResponse} from "../../lib/customerInterface/interfaces";
import {AddressDto, IAddressResponseDto} from "../../lib/dto/AddressDto";
import {addAddress} from "../../store/slice/UserSlice";
import {ApplicationError} from "../../lib/ApplicationError";
import {StorageManager} from "../../util/DataUtil";
import {resetAllRecord} from "../../store/slice/AuthSlice";
import {clearCurrentBooking, CurrentBooking, updateBooking, updateCoupon} from "../../store/slice/StateUtil";
import {showToast} from "../../helper/GlobalToast";

export const Home = () => {
    const  navigate=useNavigate();
    const {showModal} =useGlobalModalContext();
    // const pincode = useSelector((state: RootState) => state.StateUtil.autoAddress.pincode);
    const area = useSelector((state: RootState) => state.StateUtil.autoAddress.area);
    const customerId = useSelector((state: RootState) => state.authSlice.customerId);
    const servinceNotAvaialble = useSelector((state: RootState) => state.StateUtil.service.serviceNotAvailable);
    const noService = useSelector((state: RootState) => state.StateUtil.service.noService);
    const token = useSelector((state: RootState) => state.authSlice.token);
    const activeBooking = useSelector((state: RootState) => state.StateUtil.booking);
    const addressList=useSelector((state:RootState)=>state.UserSlice.address);
    const [modalEnable,setModalEnable]=useState(true)

   const dispatch=useDispatch<AppDispatch>();
    const singleDay="Per Day";
    const multipleDay="Multiple Days";
    useEffect(()=>{
        if(addressList.length===0)
        APIS.fetchData<CommonResponse<AddressDto[]>>("GET", APIEndpoint.GET_ADDRESS,null,{
            headers:{
                AUTHORIZATION:`Bearer ${token}`
            },
            params:{
                customerId:customerId
            }
        }).
        then(response=>{
            if(response.statusCode===APIConstant.SUCCESS_STATUS_CODE) {
                 const addressDto=response.data;
                 dispatch(addAddress(addressDto))
            }
        }).catch(err=>{
            if(err instanceof ApplicationError) {
                if(err.statusCode===APIConstant.UNAUTHORIZED) {
                    StorageManager.deleteData("user")
                    dispatch(resetAllRecord(""))
                }
            }
        });
    },[])
    // useEffect(()=>{
    //     if(!area && !noService && !servinceNotAvaialble)
    //         showModal(MODAL_TYPES.PINCODE);
    // },[])

    const navigateToBooking=(type:String)=> {
        console.log(type)
      if(type===singleDay) {
          navigate(ROUTES.PER_DAY_BOOKING)
      }
      if(type===multipleDay)
          navigate(ROUTES.MULTIPLE_DAY_BOOKING)
      // type==="Per Day"?navigate(Lo)
      //   navigate(ROUTES.OTP);
    }
    // useEffect(()=>{
    //     console.log("pincode out modal called")
    //     if(!noService && !servinceNotAvaialble) {
    //
    //         console.log("pincode modal called")
    //         showModal(MODAL_TYPES.PINCODE);
    //     }
    //     // return area === "" || area === undefined ?showModal(MODAL_TYPES.PINCODE) : <></>;
    //
    // },[!noService,!servinceNotAvaialble, !area])
useEffect(()=>{
    if(activeBooking) {

        dispatch(clearCurrentBooking())
    }
    APIS.getCoupon().then(response=>{
        if(response.statusCode===APIConstant.SUCCESS_STATUS_CODE) {
dispatch(updateCoupon(response.data))
        }

    }).catch((error:ApplicationError)=>{
        switch (error.statusCode) {
            case APIConstant.NETWORK_ERROR:
                showToast(APIConstant.NETWORK_ERROR_MESSAGE)
                break;
        }
    })
},[])
    const showPincode=()=>{
        return (
            <>

            </>
        )
    }

    return (
        <>

            {servinceNotAvaialble &&(
                <NotServicable/>
            )}
            {noService &&(
                <NoService/>
            )}

            {!servinceNotAvaialble && !noService && (

                <>
            <Header/>

                    {/*{setModalEnable(false)}*/}
            <div className="home">
                <img src={HomeImg}/>
                <div className="home-content-wrapper">
                <Slider/>
                <p className="our-services">{CONTENT_DATA.homePage.services.heading}</p>
                <section className="subs-cards-sec">
                    {
                        CONTENT_DATA.homePage.services.type.map(({type,img}) => (
                            <SubscriptionCard key={type} name={type} image={img} onclick={()=>navigateToBooking(type)}/>
                        ))
                    }
                </section>
                </div>
            </div>
                    </>
            )}
        </>
    )
}
export default Home