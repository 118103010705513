import './Dashboard.scss';
import {Form, Formik} from "formik";
import {AddressValidation} from "../../schemas/FormErrors";
import {AddressDto, IAddressRequestDto} from "../../lib/dto/AddressDto";
import APIS from "../../util/APIUtil";
import {APIConstant} from "../../util/APIConstant";
import {addNewAddress, updateActiveAddress} from "../../store/slice/UserSlice";
import {showToast} from "../../helper/GlobalToast";
import {BackArrow, MapStaic, TOAST_MESSAGE} from "../../lib/const";
import {ApplicationError} from "../../lib/ApplicationError";
import {StorageManager} from "../../util/DataUtil";
import {resetAllRecord} from "../../store/slice/AuthSlice";
import {CONTENT_DATA} from "../../lib/content";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store/store";
import {MODAL_TYPES, useGlobalModalContext} from "../../helper/GlobalModal";
import {useNavigate} from "react-router-dom";
import {truncateText} from "../../util/AppUtils";

const AddAddress = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const {hideModal, showModal} = useGlobalModalContext();
    const predictAddress = useSelector((state: RootState) => state.StateUtil.predictedAddress);
    console.log("predicted address is ",predictAddress)
    return (
        <div className="booking-page-wrapper">
            <Formik
                key="address-form"
                initialValues={{
                    houseNo: "",
                    landmark: "",
                    addressName: "",

                }}
                validationSchema={AddressValidation}
                onSubmit={(values, errors) => {
                    const addressRequestDto: IAddressRequestDto = {

                         pinCode: predictAddress?.pinCode,
                        flatNo: values.houseNo,
                        addressName: values.addressName,
                        city: predictAddress?.city,
                        landMark: values.landmark,
                        mainAddress:predictAddress?.structured_formatting.main_text,
                        subAddress: predictAddress?.structured_formatting.secondary_text,
                        latitude: predictAddress?.geometry.location.lat,
                        longitude: predictAddress?.geometry.location.lng

                        // street: values.street
                    }

                    APIS.addAddress(addressRequestDto)
                        .then(response => {


                            if (response.statusCode === APIConstant.SUCCESS_STATUS_CODE) {
                                const addressResponse = response.data;
                                const addressDto: AddressDto = {

                                    addressId: addressResponse.addressId,
                                    addressName: addressResponse.addressName,
                                    area: addressResponse.area,
                                    city: addressResponse.city,
                                    flatNo: addressResponse.flatNo,
                                    landMark: addressResponse.landMark,
                                    pincode: addressResponse.pincode,
                                    street: addressResponse.street,
                                    mainAddress:addressResponse.mainAddress,
                                    subAddress:addressResponse.subAddress
                                }
                                dispatch(addNewAddress(addressDto));
                                dispatch(updateActiveAddress(addressDto));
                                showToast(TOAST_MESSAGE.ADDRESS_ADDED_SUCCESSFULLY)
                                // hideModal();
                                navigate(-1);

                            } else {
                                //todo do what to do when address not saved
                            }
                        }).catch(err => {
                        if (err instanceof ApplicationError) {
                            switch (err.statusCode) {
                                case APIConstant.BAD_REQUEST:
                                    showToast(APIConstant.BAD_REQUEST_MESSAGE);
                                    break;
                                case APIConstant.INTERNAL_SERVER_ERROR:
                                    showToast(APIConstant.INTERNAL_SERVER_ERROR_MESSAGE);
                                    break;
                                case  APIConstant.UNAUTHORIZED: {
                                    showToast(APIConstant.UNAUTHORIZED_MESSAGE)
                                    StorageManager.deleteData("user")
                                    dispatch(resetAllRecord(""))
                                    break;
                                }
                            }
                        }
                    })


                }}
            >
                {({
                      values,
                      handleChange,
                      handleSubmit,
                      handleBlur,
                      errors,
                      isValid
                  }) => (
                    <Form onSubmit={handleSubmit} className="booking-page">
                        <img className="back-arrow" src={BackArrow} alt="BackArrow" onClick={() => navigate(-1)}/>
                        <p className="title">{CONTENT_DATA.address.heading}</p>
                        <div className="input-wrapper">
                            <label>{CONTENT_DATA.address.selectAddress}</label>
                            <button name="pincode"
                                    onClick={() => showModal(MODAL_TYPES.PINCODE)}>{CONTENT_DATA.address.selectAddress}</button>
                        </div>
                        {predictAddress && (
                                <div className="response-data">
                                    <img src={MapStaic} className="static-img" alt="MapStaic"/>
                                    <section className="text-wrapper">
                                        <p className="primary-text">{truncateText(predictAddress.structured_formatting.main_text, 37)}</p>
                                        <p className="secondary-text">{predictAddress.structured_formatting.secondary_text}</p>
                                    </section>
                                </div>
                        )}
                        <div className="input-wrapper">
                            <label>{CONTENT_DATA.address.addressName}</label>
                            <input type="text" name="addressName" maxLength={CONTENT_DATA.address.nameLength}
                                   placeholder={CONTENT_DATA.address.addressName}
                                   autoComplete="off"
                                   // onBlur={handleBlur}
                                   onChange={handleChange}
                                // disabled={!validPinCode}
                                   value={values.addressName}/>
                        </div>
                        <div className="input-wrapper">
                            <label>{CONTENT_DATA.address.houseNo}</label>
                            <input type="text" name="houseNo" maxLength={CONTENT_DATA.address.houseNoLength}
                                   placeholder={CONTENT_DATA.address.houseNo}
                                   autoComplete="off"
                                   // onBlur={handleBlur}
                                   onChange={handleChange}
                                // disabled={!validPinCode}
                                   value={values.houseNo}/>
                        </div>
                        <div className="input-wrapper">
                            <label>{CONTENT_DATA.address.landmark}</label>
                            <input type="text" name="landmark"
                                   placeholder={CONTENT_DATA.address.landmark}
                                   autoComplete="off"
                                   // onBlur={handleBlur}
                                   onChange={handleChange}
                                // disabled={!validPinCode}
                                   value={values.landmark}/>
                        </div>


                        <button disabled={!isValid} type="submit">{CONTENT_DATA.address.addAddressBtn}</button>
                    </Form>
                )}
            </Formik>

        </div>

    )
}
export default AddAddress;