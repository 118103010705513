import './style.css'
import Slider from "react-slick";

import React, {useEffect, useState} from "react";
import {CONTENT_DATA} from "../../lib/content";
import {PromoInterface} from "../../lib/customerInterface/ExpressInteface";
import CONNECTAPI from "../../util/ConnectAPI";

const Sliders: React.FC = () => {
    const [promo,setPromo]=useState<PromoInterface[]>()
    const settings = {
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
    };
    useEffect(()=>{
        // CONNECTAPI.getPromo()
        //     .then(response=>{
        //         setPromo(response)
        //     })
    })


    return (
        <div className="slider">
            <Slider {...settings}>
                {
                    CONTENT_DATA.slides.map((({name, img}) => (
                        <div key={name}><img src={img} alt={name}/></div>
                    )))
                }
            </Slider>
        </div>
    )
}

export default Sliders;