import PaymentSuccessLoading from "../../../assets/lottie/PaymentSuccessLoading";

const PaymentSuccess = () => {
     return (
      <div>
        <PaymentSuccessLoading/>

      </div>

  )
}
export default PaymentSuccess;