import {pageNotFound} from "../../../lib/const";
import SecondaryLayout from "../../../layout/SecondaryLayout";

export  const PageNotFound=()=>{
    return(
        <SecondaryLayout image={pageNotFound} >

        </SecondaryLayout>
    )
}
export default PageNotFound