import {combineReducers, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AddressDto} from "../../lib/dto/AddressDto";
import {store} from "../store";

interface IAddressState{
    address:AddressDto[];
    activeAddress:AddressDto;
}
const addressUtil:IAddressState={
    address:[],
    activeAddress:{
        addressId:'',
        addressName:'',
        street:'',
        pincode:'',
        flatNo:'',
        city:'',
        area:'',
        landMark:'',
        subAddress:'',
        mainAddress:''
    }
}

const addressSlice=createSlice({
    name:"addressUtil",
    initialState:addressUtil,
    reducers:{
        addAddress(state,action:PayloadAction<AddressDto[]>) {

            action.payload.forEach(newAddress => {
                    if (state.address.length > 0) {
                        const isDuplicate = state.address.some(existingAddress => existingAddress.addressId === newAddress.addressId);
                        if (!isDuplicate) {
                            state.address.push(newAddress);
                            state.activeAddress = action.payload[0]
                        }
                    }
                    else {
                        state.address=action.payload;
                        // const address = state.address.find(existingAddress => existingAddress.addressId === newAddress.addressId);

                        state.activeAddress=action.payload[0]
                        // if(address)
                        // state.activeAddress=address;
                    }
            });
        },
        deleteAndUpdateAddress(state,action:PayloadAction<AddressDto>){
            console.log("addressList",action.payload)
          // const pincode=store.getState().StateUtil.pincode;
            const addressList = state.address.filter(address => address.addressId !== action.payload.addressId);

            console.log("addressList record", addressList);

            // Update the address list in the state
            state.address = addressList;
        // const activeAddress = addressList.find(address=>address.pincode===pincode);
        // if(activeAddress)
        //     state.activeAddress=activeAddress;
        // else
            state.activeAddress=addressList[0]
          },
        addNewAddress(state,action:PayloadAction<AddressDto>) {

            if(state.address.length===0) {
                   state.address.push(action.payload);
                state.activeAddress=action.payload;
               }
            else {
                state.address.push(action.payload)
            }


            },
        updateActiveAddress(state,action:PayloadAction<AddressDto>){
            state.activeAddress=action.payload;
        },
        resetUserSlice:(state,action:PayloadAction<string>)=>{
            return addressUtil;
        }
        }

})

export const {resetUserSlice,addAddress
    ,addNewAddress,updateActiveAddress,deleteAndUpdateAddress} = addressSlice.actions;

// const AddressUtilReducer = combineReducers({
//     addressUtilSlice: addressSlice.reducer,
//
// })


export default addressSlice.reducer;
