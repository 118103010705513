import './Layout.scss'
import React, {ReactNode} from "react";
import {Logo} from "../lib/const";

type LayoutProps = {
    image: string;
    children: ReactNode;
}
const SecondaryLayout: React.FC<LayoutProps> = ({children, image}) => {
    return (
        <div className="secondaryLayout">
            <section className="logo-section">
                <img src={Logo} className="logo" alt="logo"/>
            </section>
            <img src={image} className="mainImage" alt="mainImage"/>
            <section className="content">
                {children}
            </section>

        </div>
    )
}
export default SecondaryLayout