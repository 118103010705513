import './Popups.scss'
import Popup from "../../helper/Popup";
import {PincodeValidation} from "../../schemas/FormErrors";
import {CommonResponse, IReacableResponse} from "../../lib/customerInterface/interfaces";
import {CONTENT_DATA} from "../../lib/content";
import {PincodeImg} from "../../lib/const";
import {Form, Formik} from "formik";
import React from "react";
import {useGlobalModalContext} from "../../helper/GlobalModal";
import APIS from "../../util/APIUtil";
import {APIConstant, APIEndpoint} from "../../util/APIConstant";

import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store/store";
import {
    IServiceState, updateActiveSlot,
    updateNoService,
    updateAutoAddressAndPincode,
    updateServiceDetail
} from "../../store/slice/StateUtil";
import {ApplicationError} from "../../lib/ApplicationError";
import {StorageManager} from "../../util/DataUtil";
import {resetAllRecord} from "../../store/slice/AuthSlice";
import {showToast} from "../../helper/GlobalToast";
import {updateActiveAddress} from "../../store/slice/UserSlice";


const PinCode = () => {
    const {hideModal} = useGlobalModalContext();

    const dispatch = useDispatch<AppDispatch>();
    const pincode = useSelector((state: RootState) => state.StateUtil.autoAddress.pincode);
    const addressList=useSelector((state:RootState)=>state.UserSlice.address)
    const servinceNotAvaialble = useSelector((state: RootState) => state.StateUtil.service.serviceNotAvailable);
    const noService = useSelector((state: RootState) => state.StateUtil.service.noService);
    const token = useSelector((state: RootState) => state.authSlice.token);
    const updateAddressByPincode=(pincode:string)=>{
        const address = addressList.find(address=>address.pincode===pincode);
        if(address)
            dispatch(updateActiveAddress(address))
    }
    return (
        <Popup canClose={() => {
            if (pincode !== '' || servinceNotAvaialble || noService) {
                hideModal()
            }
        }}>

            <Formik
                key="pincode-form"
                initialValues={{
                    pincode: "",
                }}
                validationSchema={PincodeValidation}
                onSubmit={(values, errors) => {

                    APIS.getServiceDetail(values.pincode)
                        .then(res => {

                            if (res.statusCode === APIConstant.SUCCESS_STATUS_CODE) {
                                // dispatch(updateAutoAddressAndPincode(values.pincode))
                                dispatch(updateServiceDetail(res.data))
                                updateAddressByPincode(values.pincode);


                            }
                            else if (res.statusCode === APIConstant.FAILURE_STATUS_CODE && res.statusMessage === APIConstant.NOT_ACCEPTING_ANY_FURTHER_REQUEST) {
                                                    showToast(APIConstant.NOT_ACCEPTING_ANY_FURTHER_REQUEST);
                                }
                            else {
                                showToast(APIConstant.SERVICE_NOT_AVAILABLE);
                            }
                        }).catch(err => {
                                    if (err instanceof ApplicationError) {
                                        switch (err.statusCode) {
                                            case APIConstant.BAD_REQUEST:
                                                showToast(APIConstant.BAD_REQUEST_MESSAGE);
                                                break;
                                            case APIConstant.INTERNAL_SERVER_ERROR:
                                                showToast(APIConstant.INTERNAL_SERVER_ERROR_MESSAGE);
                                                break;
                                            case  APIConstant.UNAUTHORIZED: {
                                                showToast(APIConstant.UNAUTHORIZED_MESSAGE)
                                                StorageManager.deleteData("user")
                                                dispatch(resetAllRecord(""))
                                                break;
                                            }
                                            case APIConstant.NETWORK_ERROR:
                                                showToast(APIConstant.NETWORK_ERROR_MESSAGE)
                                                break;
                                        }


                                    }
                                }).finally(()=>{
                                    updateActiveSlot([]);
                                    hideModal()});
                            }}



            >
                {({
                      values,
                      handleChange,
                      handleSubmit,
                      handleBlur,
                      errors,
                  }) => (
                    <Form className="pincode-wapper" onSubmit={handleSubmit}>
                        <img src={PincodeImg} alt={'Pincode Img'}/>
                        <p className="title">{CONTENT_DATA.pinCode.heading}</p>
                        <input type="tel" name="pincode" maxLength={CONTENT_DATA.pinCode.maxLength}
                               placeholder={CONTENT_DATA.pinCode.input}
                               autoComplete="off"
                               onBlur={handleBlur}
                               onChange={handleChange}
                               value={values.pincode}/>
                        {errors.pincode && values.pincode.length !== CONTENT_DATA.pinCode.maxLength && (
                            <p className="error">{errors.pincode}</p>
                        )}
                        <button type="submit"
                                disabled={values.pincode.length !== CONTENT_DATA.pinCode.maxLength}>{CONTENT_DATA.pinCode.btnName}</button>
                    </Form>
                )}
            </Formik>
        </Popup>
    )
}
export default PinCode;