import './errors.scss';
import {MODAL_TYPES, useGlobalModalContext} from "../../../helper/GlobalModal";
import {LocationNotServicable} from "../../../lib/const";

const NoService = () => {
    const {showModal}=useGlobalModalContext();
    return (
        <div className="error-wrapper no-service inside-dashboard">
            <p>In Current location No service available</p>
            <button onClick={()=>  showModal(MODAL_TYPES.PINCODE)}>TRY ANOTHER LOCATIONS</button>
            <img className="no-service" src={LocationNotServicable} alt="LocationNotServicable"/>

        </div>
    )
}
export default NoService;