import Popup from "../../helper/Popup";
import {MODAL_TYPES, useGlobalModalContext} from "../../helper/GlobalModal";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store/store";
import {ReactComponent as Add} from "../../assets/images/add.svg";
import {ReactComponent as Delete} from '../../assets/images/delete.svg';

import AddressDetails from "../addressDetails/AddressDetails";
import React from "react";
import APIS from "../../util/APIUtil";
import {APIConstant} from "../../util/APIConstant";
import {showToast} from "../../helper/GlobalToast";
import {ROUTES, TOAST_MESSAGE} from "../../lib/const";
import {AddressDto} from "../../lib/dto/AddressDto";
import {deleteAndUpdateAddress} from "../../store/slice/UserSlice";
import {ApplicationError} from "../../lib/ApplicationError";
import {StorageManager} from "../../util/DataUtil";
import {resetAllRecord} from "../../store/slice/AuthSlice";
import {useNavigate} from "react-router-dom";
import addressDetails from "../addressDetails/AddressDetails";

const EditAddress = () => {
    const {hideModal, showModal} = useGlobalModalContext();
    const addressList = useSelector((state: RootState) => state.UserSlice.address);
const navigate=useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const updateAddress = (address: AddressDto) => {
        console.log("update address called")
        dispatch(deleteAndUpdateAddress(address))
    }
       const deleteAddress = (address: AddressDto) => {
           console.log("deleting")
        APIS.deleteAddress(address.addressId)
            .then(response => {
                if (response.statusCode === APIConstant.SUCCESS_STATUS_CODE) {
                    console.log("delete called")
                    updateAddress(address);
                    // showToast(TOAST_MESSAGE.ADDRESS_DELETED_SUCCESSFULLY);
                    //     todo update address list and active address
                } else {
                    if (response.statusCode === APIConstant.FAILURE_STATUS_CODE && response.statusMessage=== APIConstant.ADDRESS_CONTAIN_BOOKING) {
                        console.log(response.statusMessage)
                        showToast(TOAST_MESSAGE.ADDRESS_CONTAIN_BOOKING);
                    }
                    else {
                        updateAddress(address);
                    }
                }
            }).catch(err => {
            console.log("delete err",err);
            if (err instanceof ApplicationError) {
                switch (err.statusCode) {
                    case APIConstant.BAD_REQUEST:
                        showToast(APIConstant.BAD_REQUEST_MESSAGE);
                        break;
                    case APIConstant.INTERNAL_SERVER_ERROR:
                        showToast(APIConstant.INTERNAL_SERVER_ERROR_MESSAGE);
                        break;
                    case  APIConstant.UNAUTHORIZED: {
                        showToast(APIConstant.UNAUTHORIZED_MESSAGE)
                        StorageManager.deleteData("user")
                        dispatch(resetAllRecord(""))
                        break;
                    }
                    case APIConstant.NETWORK_ERROR:
                        showToast(APIConstant.NETWORK_ERROR_MESSAGE)
                        break;
                }


            }
        });
    }
    console.log("address list is ",addressList)
    return (

        <Popup canClose={() => hideModal()} modalBgClass="address-popup">
            {

                addressList.map((addressDetail) => (
                    <div className={"edit-address-wrapper"}>
                        <AddressDetails
                            key={addressDetail.addressId}
                            checked={false}
                            handleCheck={() => {
                            }}
                            name={addressDetail.addressName}
                            address={`${addressDetail.flatNo} ${addressDetail.subAddress}
                            `}
                            isButton={false}
                            hideRadio={true}
                        />
                        <Delete onClick={() => deleteAddress(addressDetail)}/>
                    </div>
                ))
            }
            <p className="large-font" onClick={() => {
                hideModal();
                navigate(ROUTES.ADD_ADDRESS)
            }}><Add/> Add Address</p>
        </Popup>
    );
}
export default EditAddress;