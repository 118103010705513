// NavigateContext.tsx
import React, { createContext, useContext, ReactNode } from 'react';
import {NavigateFunction, useNavigate, useNavigate as useReactRouterNavigate} from 'react-router-dom';

// Create context for navigation
export const NavigateContext = createContext<NavigateFunction | undefined>(undefined);

// Custom hook for accessing the navigation function
export const useNavigates = () => {
    const navigates = useContext(NavigateContext);
    if (!navigates) {

        throw new Error('useNavigate must be used within a NavigateProvider');
    }
    return navigates;
};

// Component to wrap the application and provide navigation context
export const NavigateProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const navigate = useNavigate(); // Use React Router's useNavigate hook

    return (
        <NavigateContext.Provider value={navigate}>

            {children}
        </NavigateContext.Provider>
    );
};
