import loader from "./loading.json";

import Lottie from "lottie-react";

const style ={
    width:"20rem"
}

const Loader = () => {
    return (
        <Lottie animationData={loader} style={style}/>
    )
}
export default Loader;