import PaymentSuccessLoading from "../../../assets/lottie/PaymentSuccessLoading";
import PaymentFailureLoading from "../../../assets/lottie/PaymentFailureLoading";

const PaymentFailure = () => {
    return (
        <div>
            <PaymentFailureLoading/>

        </div>

    )
}
export default PaymentFailure;