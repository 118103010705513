import home from '../assets/images/bottomMenu/Home.svg';
import historyLogo from '../assets/images/bottomMenu/historyLogo.svg';
import profile from '../assets/images/bottomMenu/profile.svg';
import subscription from '../assets/images/bottomMenu/subscription.svg';
import PincodeImg from '../assets/images/pincode.svg';
import getStarted from '../assets/images/getStarted.png';
import pageNotFound from '../assets/images/pageNotFound.svg';
import otpImg from '../assets/images/otp.png';
import profileUpdateImg from '../assets/images/profileUpdate.png';
import perDay from '../assets/images/homepage/perDay.svg';
import multiDay from '../assets/images/homepage/multipleBooking.svg';
import Logo from "../assets/images/Logo.svg";
import HomeImg from "../assets/images/Home.png";
import Location from "../assets/images/location_track.svg";
import Dropdown from "../assets/images/dropdown.svg";
import SlideOne from "../assets/images/dropdownOne.png";
import Calender from "../assets/images/Calendar.svg";
import Slot from "../assets/images/ClockCountdown.svg";
import BackArrow from "../assets/images/LeftArrow.svg";
import User from "../assets/images/User.svg";
import ProfileBg from "../assets/images/profile-bg.png";
import LocationNotServicable from '../assets/images/location-not-servicable.png'
import NoHistory from '../assets/images/noHistory.svg'
import Delete from '../assets/images/delete.svg';
import  OneTimeCoupon from '../assets/images/oneTimeCoupon.png'
import OneDayCoupon from'../assets/images/oneDayCoupon.png'
import WeeklyCoupon from '../assets/images/WeeklyCoupon.png'
import MonthlyCoupon from '../assets/images/MonthlyCoupon.png'
import MapStaic from '../assets/images/map-static.png'
import SomethingWentWrongImg from '../assets/images/somethingWentWrong.svg'
import WhatsAppIcon from '../assets/images/bottomMenu/whatsapp.svg'
export {
    OneTimeCoupon,
    OneDayCoupon,
    MonthlyCoupon,
    WeeklyCoupon,
    subscription,
    historyLogo,
    profile,
    home,
    PincodeImg,
    profileUpdateImg,
    otpImg,
    getStarted,
    Logo,
    HomeImg,
    Location,
    Dropdown,
    perDay,
    multiDay,
    pageNotFound,
    SlideOne,
    Calender,
    Slot,
    BackArrow,
    ProfileBg,
    User,
    LocationNotServicable,
    NoHistory,
    Delete,
    MapStaic,
    SomethingWentWrongImg,
    WhatsAppIcon

}


export const ROUTES = {
    STATIC_PAGES: '/',
    LOGIN: '/login',
    OTP: "/otp",
    PROFILE_UPDATE: "/profileUpdate",
    DASHBOARD: "/dashboard",
    HOME: "/dashboard/home",
    PER_DAY_BOOKING: "/perDay",
    MULTIPLE_DAY_BOOKING: "/multipleDay",
    BOOKING: "/dashboard/subscription",
    HISTORY: "/dashboard/history",
    PROFILE: "/dashboard/profile",
    SEARCH_LOCATION: "/searchLocation",
    PAGE_NOT_FOUND:"/notfound",
    PAYMENT_SUCCESS:"/success",
    PAYMENT_FAILURE:"/failure",
    SOMETHING_WENT_WRONG:"/wentWrong",
    BOOKING_PREVIEW:"/preview",
    NO_SERVICE:"/dashboard/noService",
    NO_INTERNET:"/noInternet",
    NOT_SERVICEABLE:"/dashboard/notServiceable",
    COUPON:'/coupon',
    ADD_ADDRESS:'/address'



}


export const CARD_TYPE = [
    {
        name: "One Time"
    },
    {
        name: "One Day"
    },

]
const serviceTypes=new Map<string,string>();
serviceTypes.set("ONE TIME","ONE_TIME");
serviceTypes.set("ONE DAY","ONE_DAY");
serviceTypes.set("WEEKLY","WEEKLY");
serviceTypes.set("FIFTEEN DAYS","FIFTEEN_DAYS");
serviceTypes.set("MONTHLY","MONTHLY");
export const COMMON_RESPONSE = {
    SUCCESS_CODE: 1000,
    COMPLETED:"COMPLETED",
    FAILED:"fAILED",
    map:serviceTypes,
    LOGOUT_SUCCESS:'Logged out successfully',
    CONFIRM:'CONFIRM',
    IN_PROGRESS:'IN_PROGRESS',
    PENDING:'PENDING',
    PER_DAY:'PER_DAY',
    MULTIPLE_DAY:'MULTIPLE_DAY'

}
export const TOAST_MESSAGE={
    ADDRESS_CONTAIN_BOOKING:"can't delete address contain active booking",
    ADDRESS_DELETED_SUCCESSFULLY:'Address deleted successfully',
    SERVICE_NOT_AVAILABLE_AT:'OOPS Service not available at',
    TEMPORARILY_SERVICE_NOT_AVAILABLE_AT:'Temporarily Service not available at',
    ALL_FIELDS_ARE_MANDATORY:'All Fields are mandatory !!',
    ADDRESS_NOT_MATCHED_WITH_PINCODE:'address not matched with pincode ',
    INVALID_NAME_OR_EMAIL:'invalid name or email',
    OTP_RESEND_SUCCESSFULLY:'OTP resend successfully',
    ADDRESS_ADDED_SUCCESSFULLY:'Address added successfully'
}
export enum BOOKINGSTATUS{
    FAILED,
    COMPLETED,
    PENDING,
    CONFIRM
}

