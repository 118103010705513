import './Dashboard.scss';
import {COMMON_RESPONSE, ProfileBg, User} from "../../lib/const";
import {IUserDetail} from "../../lib/customerInterface/interfaces";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store/store";
import {resetAllRecord} from "../../store/slice/AuthSlice";
import {CONTENT_DATA} from "../../lib/content";
import {MODAL_TYPES, useGlobalModalContext} from "../../helper/GlobalModal";
import {showToast} from "../../helper/GlobalToast";
import localStorage from "redux-persist/es/storage";

const Profile = () => {
    const userDetail:IUserDetail=useSelector((state:RootState)=>state.authSlice)
    const dispatch=useDispatch<AppDispatch>();
    const{showModal} = useGlobalModalContext();
    const logout=()=>{
        // StorageManager.deleteData("persist:user")
        localStorage.removeItem("user")

        showToast(COMMON_RESPONSE.LOGOUT_SUCCESS)
        dispatch(resetAllRecord(""));
    }
    return (
        <div className="profile">
            <section className="profile-card-wrapper">
                <img src={ProfileBg} alt={"ProfileBg"}/>
                <div className="profile-details-wrapper">
                    <img src={User} alt="user-logo"/>
                    <p className="name">{userDetail.name}</p>
                    <p className="oth">{userDetail.mobileNumber}</p>
                    <p className="oth email">{userDetail.email}</p>
                </div>
                {/*<button className="edit">Edit</button>*/}
                <button className="logout" onClick={()=>logout()}>{CONTENT_DATA.profilePage.logoutBtn}</button>
            </section>
            <section className="profile-address" onClick={()=>showModal(MODAL_TYPES.EDIT_ADDRESS)}>
                <p className="content">{CONTENT_DATA.profilePage.address}</p>
                <p className="content small-font">{CONTENT_DATA.profilePage.edit}</p>
            </section>

            <section className="contact-us" onClick={()=>showModal(MODAL_TYPES.CONTACT_US)}>
                <p className="content">{CONTENT_DATA.profilePage.contactUs}</p>
            </section>

            <section className="t-and-c" onClick={()=>showModal(MODAL_TYPES.TERMS_AND_CONDITION)}>
                <p className="content">{CONTENT_DATA.profilePage.termsAndCondition}</p>

            </section>

        </div>
    )
}
export default Profile;