import './errors.scss';
import { StorageManager} from "../../../util/DataUtil";
import {useEffect, useState} from "react";

const NoInternet = () => {
    const [image,setImage]=useState<string|undefined>(undefined);
useEffect(()=>{
    getImage()
},[image])
    const getImage=()=>{
     const image = StorageManager.getImage();

       image.then(async res => {
           if (res)
               setImage(await image);
       })
    }
    return (
        <div className="error-wrapper no-connection">
            { image &&(
            <img src={image} alt="NoConnection"/>
            )}
            <p> Connect The Internet</p>
        </div>
    )
}
export default NoInternet;