import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IServiceResponse} from "../../lib/dto/IServiceResponse";
import {AutoCompleteResponse, CouponResponse, ISubscriptionResponse} from "../../lib/customerInterface/interfaces";
import {
    resetActiveBooking, resetActiveCouponState,
    resetActiveSlotState, resetCouponState, resetPaidState, resetServiceDetail,
    resetServiceDetailState,
    resetServiceState,
    resetSlotState, resetSubscriptionList
} from "./util/storeUtil";
export interface IAutoAddress{
    pincode:string,
    area:string
}

export interface IPredictedAddressData {
    pinCode: string;
    city: string;
    geometry: {
        location: {
            lat: string;
            lng: string;
        }
    };
    structured_formatting: {
        main_text: string;
        secondary_text: string;
    }
}
interface IStateUtil {

    autoAddress:IAutoAddress,
    service: IServiceState
    slot: ISlot
    activeSlots: string[],
    serviceDetail: IServiceResponse,
    booking:CurrentBooking,
    paid:string,
    coupons:CouponResponse[],
    activeCoupon:CouponResponse|undefined,
    subscriptionList:ISubscriptionResponse[]|undefined,
    predictedAddress:IPredictedAddressData|undefined;


}

export interface IServiceState {
    noService: boolean;
    serviceNotAvailable: boolean
}
export interface CurrentBooking {
    subscriptionType?:string,
    startDate:string,
    slot:string[],
    cuisineType:string,
    numberOfPerson:number,
    numberOfBurner:string,
    chefPreferred?:string,
    couponCode?:string,
    discountPrice?:number,
    pincode:string
}

export interface ISlot {
    slots: string[]
}


const stateUtil: IStateUtil = {
    autoAddress:{
        pincode: '',
        area: ''
    },

    service: {
        noService: true,
        serviceNotAvailable: true
    },
    slot: {
        slots: []
    },
    activeSlots: [],
    serviceDetail: {
        areaList: [],
        pricePerMin: 0,
        contactNumber:'',
        cuisineType: [],
        details: {
            pincode: '',
            services: []
        }
    },
    booking: {
        startDate: '',
        cuisineType: '',
        slot: [],
        numberOfBurner: '',
        subscriptionType: '',
        numberOfPerson: 0,
        pincode: ''
    },
    paid: '',
    coupons: [],
    activeCoupon: undefined,
    subscriptionList:undefined,
    predictedAddress:undefined
}


const stateUtilSlice = createSlice({
    name: "stateUtilSlice",
    initialState: stateUtil,
    reducers: {
        updateAutoAddressAndPincode(state, action: PayloadAction<IAutoAddress>) {

            state.autoAddress = action.payload;

        },
        updateNoService(state, action: PayloadAction<IServiceState>) {
            console.log(action)
            const serviceState = action.payload;
            state.service.noService = serviceState.noService;
            state.service.serviceNotAvailable = serviceState.serviceNotAvailable;

        },
        updateSlot(state, action: PayloadAction<string[]>) {
            console.log("update Slot Called?")
            state.slot.slots = action.payload
        },
        updateActiveSlot: (state, action: PayloadAction<string[]>) => {

            state.activeSlots = action.payload;

        },
        updateServiceDetail: (state, action: PayloadAction<IServiceResponse>) => {
            console.log("update service called? ", action.payload)
            state.serviceDetail = action.payload
        },
        updateBooking: (state, action: PayloadAction<CurrentBooking>) => {
            state.booking = action.payload
        },
        updatePaymentStatus: (state, action: PayloadAction<string>) => {
            state.paid = action.payload
        },
        resetStateUtilData: (state, action: PayloadAction<string>) => {
            state.booking = resetActiveBooking();
            state.service = resetServiceState();
            state.slot = resetSlotState();
            state.activeSlots = resetActiveSlotState();
            state.paid = resetPaidState();
            state.activeCoupon = resetActiveCouponState();
            state.coupons = resetCouponState();
            state.subscriptionList=resetSubscriptionList();
            // state.serviceDetail=resetServiceDetail();
            // state.pincode=''

            // return stateUtil;
        },
        clearCurrentBooking: (state) => {
            const current: CurrentBooking = {
                startDate: '',
                numberOfPerson: 0,
                subscriptionType: '',
                numberOfBurner: '',
                slot: [],
                cuisineType: '',
                chefPreferred: '',
                pincode: ''
            }
            state.booking = current;
        },
        updateCoupon: (state, action: PayloadAction<CouponResponse[]>) => {
            state.coupons = action.payload;
        },
        updateActiveCoupon: (state, action: PayloadAction<CouponResponse | undefined>) => {
            state.activeCoupon = action.payload;
        },
        updateSubscriptionList: (state, action: PayloadAction<ISubscriptionResponse[]>) => {

            state.subscriptionList = action.payload;
            console.log(state.subscriptionList)
        },
        updatePredictedAddress:(state,action: PayloadAction<IPredictedAddressData>)=>{
          state.predictedAddress=action.payload
        },

    }})



export const {updateAutoAddressAndPincode,resetStateUtilData,
    updateNoService,
    updateActiveSlot,
    updateSlot,
    updateServiceDetail,
    updateBooking,
    updatePaymentStatus,clearCurrentBooking,
    updateCoupon,updateActiveCoupon,updateSubscriptionList,
    updatePredictedAddress} = stateUtilSlice.actions;

// const StateUtilReducer = combineReducers({
//     stateUtilSlice: stateUtilSlice.reducer,
//
// })


export default stateUtilSlice.reducer;