import './StaticPages.scss';
import SecondaryLayout from "../../layout/SecondaryLayout";
import {CONTENT_DATA} from "../../lib/content";
import React, {useEffect, useRef, useState} from "react";
import {Form, Formik, FormikHelpers, FormikValues} from "formik";
import APIS from '../../util/APIUtil';
import {useNavigate} from "react-router-dom";
import {otpImg, ROUTES, TOAST_MESSAGE} from "../../lib/const";
import {IOtpValidateResponse, IRegisterRequest, IUserDetail, OtpInput} from "../../lib/customerInterface/interfaces";
import {APIConstant} from "../../util/APIConstant";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store/store";
import {updateChefDetails} from "../../store/slice/AuthSlice";
import {showToast} from "../../helper/GlobalToast";
import { Device } from '@capacitor/device';

interface OtpInputProps {
    length?: number;
    onOtpSubmit?: (otp: string) => void;
}



const Otp: React.FC<OtpInputProps> = ({length = 4}) => {
    const inputRefs = useRef<HTMLInputElement[] | null>(null);
    const [otp, setOtp] = useState<string[]>(['', '', '', '']);
    const initialValues: string[] = Array(4).fill("");
    const otpBoxReference = useRef<HTMLInputElement[]>([]);
    const navigation = useNavigate();
    const [mobile, setMobile] = useState<string>("")
    const [error, setError] = useState("");
    const mobileNumber = useSelector((state:RootState)=> state.authSlice.mobileNumber);


    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {

            setMobile(mobileNumber);
        // }
    }, [])


    const handleChanges = (e: React.ChangeEvent<HTMLInputElement>, formikValues: FormikValues, index: number) => {
        const newOtp = [...otp]; // Creating a new array
        const value = e.target.value;

        newOtp[index] = value;
        // Updating specific index
        setOtp([...otp.map((data, indx) => (indx === index ? value : data))]); // Setting state with new array

        // formikValues.setFieldValue(index, value);
        if (e.target.value && index < otpBoxReference.current.length - 1) {
            otpBoxReference.current[index + 1]?.focus(); // Use optional chaining
        }
        console.log(newOtp);
        console.log("value ", otp)
        console.log("index ", index)
    };
    const handleClick = (index: number) => {
        inputRefs.current && inputRefs.current[index]?.setSelectionRange(1, 1);

        if (index > 0 && !otp[index - 1] && inputRefs.current && inputRefs.current[index - 1]) {
            inputRefs.current[index - 1].focus();
        }
    };

    const handleBackspaceAndEnter = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (e.key === "Backspace" && !e.currentTarget.value && index > 0) {
            otpBoxReference.current[index - 1].focus();
        }
    }
    const getDeviceId = async (): Promise<string> => {
        const info = await Device.getId();
        return info.identifier;
    };
    const verifyOtp = async (errors: FormikHelpers<{ otp: string[] }>) => {
        const deviceId = await getDeviceId();
        const data: OtpInput = {
            "mobileNumber": mobile,
            "otp": otp.join(""),
            "deviceId": deviceId
        }
        // navigation(ROUTES.PROFILE_UPDATE);

        APIS.validateOtp(data).then(data => {
            console.log();
            if (data.statusCode === APIConstant.SUCCESS_STATUS_CODE) {
                const otpResponse: IOtpValidateResponse = data.data;

                console.log(data.data)
                const userDetail: IUserDetail = {
                    customerId: otpResponse.customerId,
                    deviceId: otpResponse.deviceId,
                    token: otpResponse.token,
                    isProfileUpdated: otpResponse.isProfileUpdated,
                    mobileNumber: mobile,
                    name: '',
                    email: ''
                }
                if (otpResponse.isProfileUpdated) {
                    APIS.getUserDetail(otpResponse.customerId, otpResponse.token).then(response => {
                        const userDetails: IUserDetail = {
                            customerId: otpResponse.customerId,
                            deviceId: otpResponse.deviceId,
                            token: otpResponse.token,
                            isProfileUpdated: otpResponse.isProfileUpdated,
                            mobileNumber: mobile,
                            name: response.data.name,
                            email: response.data.email
                        }
                        dispatch(updateChefDetails(userDetails));
                        navigation(ROUTES.DASHBOARD);
                    });
                } else {
                    dispatch(updateChefDetails(userDetail));
                    console.log("profile otp updated is false")
                    navigation(ROUTES.PROFILE_UPDATE);
                }
            } else {
                setError(data.statusMessage);
                errors.setFieldError("otp", data.statusMessage)
            }


        }).catch(err => {
            switch (err.statusCode) {
                case APIConstant.BAD_REQUEST:
                    showToast(APIConstant.BAD_REQUEST_MESSAGE);
                    break;
                case APIConstant.INTERNAL_SERVER_ERROR:
                    showToast(APIConstant.INTERNAL_SERVER_ERROR_MESSAGE);
                    break;
            }
        });
    }
    const resendOtp=()=>{
        const data: IRegisterRequest = {
            mobileNumber: mobile
        }
        APIS.resendOTP(data)
        .then(response => {
                if (response.statusCode === APIConstant.SUCCESS_STATUS_CODE) {
                   showToast(TOAST_MESSAGE.OTP_RESEND_SUCCESSFULLY)
                }
            })
                .catch(errors => {

                    switch (errors.statusCode) {
                        case APIConstant.BAD_REQUEST:
                            showToast(APIConstant.BAD_REQUEST_MESSAGE);
                            break;
                        case APIConstant.INTERNAL_SERVER_ERROR:
                            showToast(APIConstant.INTERNAL_SERVER_ERROR_MESSAGE);
                            break;
                    }

                })


    }

    return (
        <SecondaryLayout image={otpImg}>
            <Formik
                key="Otp-form"
                initialValues={{
                    otp: initialValues,

                }}
                // validationSchema={OtpValidation}
                onSubmit={(values, errors) => {

                verifyOtp(errors)
                }}

            >
                {({
                      values,
                      errors,
                      handleSubmit
                  }) => (
                    <Form className="page-wrapper" onSubmit={handleSubmit}>
                        <h1 className="title">{CONTENT_DATA.otp.heading}</h1>
                        <section className="otp-input-wrapper">
                            {otp.map((digit, index) => (
                                <input
                                    className="otp-input"
                                    key={index}
                                    type="tel"
                                    name={`otp${index}`}
                                    maxLength={1}
                                    autoComplete="off"
                                    ref={(ref: HTMLInputElement) => (otpBoxReference.current[index] = ref)}

                                    onChange={(e) => {

                                        handleChanges(e, values, index);

                                    }}
                                    onClick={() => handleClick(index)}
                                    onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                                    value={digit}
                                />
                            ))}
                        </section>
                        {errors.otp && (
                            <p className="error">{errors.otp}</p>
                        )}
                        <p className='resend' onClick={()=>resendOtp()}>{CONTENT_DATA.otp.didYouReceiveOTP}</p>
                        <button type="submit" disabled={otp.join("").length !== length}>
                            {CONTENT_DATA.otp.btnName}
                        </button>
                        <p className='editMobile' onClick={()=>navigation(ROUTES.STATIC_PAGES
                        )}>{CONTENT_DATA.otp.editMobile}</p>
                    </Form>
                )}
            </Formik>
        </SecondaryLayout>
    );
}

export default Otp;
