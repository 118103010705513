import './errors.scss';
import NotServicableImg from "../../../assets/images/notServicable.png";
import {MODAL_TYPES, useGlobalModalContext} from "../../../helper/GlobalModal";


const NotServicable = () => {
    const {showModal}=useGlobalModalContext();
    return (
        <div className="error-wrapper no-connection inside-dashboard">
            <p> Due to high demand currently not serviceable, Please try again later</p>
            <button onClick={()=>{
                showModal(MODAL_TYPES.PINCODE)
            }}>TRY ANOTHER LOCATIONS</button>
            <img src={NotServicableImg} alt="NotServicableImg"/>
        </div>
    )
}
export default NotServicable;