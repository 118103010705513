import './CheckBox.scss'
import React, {ChangeEvent} from "react";

type CheckBoxProps = {
    id?: string;
    name: string;
    onBlur: () => void;
    onChange: (checked: boolean) => void;
}

const CheckBox: React.FC<CheckBoxProps> = ({name, onChange, onBlur, id}) => {
    return (
        // <div className="checkbox-wrapper-40">
        //     <label className="cbx" htmlFor={id}>
        //         <input className="inp-cbx" type="checkbox" autoComplete="off" style={{display: "none"}} name={name}
        //                id={id} onChange={(e) => onChange(e.target.checked)} onBlur={onBlur}/>
        //         <span className="checkbox"></span>
        //     </label>
        // </div>
  <div className="checkbox-wrapper">
  <span className="checkbox">
    <input type="checkbox" autoComplete="off"  name={name} id={id} onChange={(e) => onChange(e.target.checked)} onBlur={onBlur}/>
    <svg>
      <use xlinkHref="#checkbox" className="checkbox"></use>
    </svg>
  </span>
            <svg xmlns="http://www.w3.org/2000/svg" style={{display: 'none'}}>
                <symbol id="checkbox" viewBox="0 0 22 22">
                    <path fill="none" stroke="#FF7400"
                          d="M5.5,11.3L9,14.8L20.2,3.3l0,0c-0.5-1-1.5-1.8-2.7-1.8h-13c-1.7,0-3,1.3-3,3v13c0,1.7,1.3,3,3,3h13 c1.7,0,3-1.3,3-3v-13c0-0.4-0.1-0.8-0.3-1.2"/>
                </symbol>
            </svg>
        </div>
    )
}
export default CheckBox;