import './AddressDetails.scss';
import React from "react";

type AddressDetailsProps = {
    checked: boolean;
    handleCheck: () => void; // Changed from handelCheck to handleCheck
    name: string;
    address: string;
    isButton: boolean;
    isButtonClick?: () => void;
    btnName?: string;
    hideRadio?: boolean;
}
const AddressDetails: React.FC<AddressDetailsProps> = ({
                                                           checked,
                                                           handleCheck,
                                                           address,
                                                           name,
                                                           isButton,
                                                           isButtonClick,
                                                           btnName,
                                                           hideRadio
                                                       }) => {

    return (
        <div className="addressDetails-wrapper">
            {!hideRadio && <div className="container">
                <label>
                    <input
                        className="round"
                        autoComplete="off"
                        type="radio"
                        checked={checked}
                        onChange={() => handleCheck()} // Changed from handelCheck to handleCheck
                    />
                    <span className="checkmark"></span>
                </label>
            </div>}
            <div className="addressDetails-content" onClick={handleCheck}>
                <p className="addressDetails-name">{name}</p>
                <p className="addressDetails-address">{address}</p>
            </div>
            {
                isButton &&
                (
                    <div>
                        <p className="select" onClick={isButtonClick}>{btnName}</p>
                    </div>
                )
            }
        </div>
    )
}
export default AddressDetails;
