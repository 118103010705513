import React, {useEffect, useRef, useState} from 'react';
import {Keyboard} from '@capacitor/keyboard';
import {useGlobalLoaderContext} from "./helper/GlobalLoader";
import APIS from "./util/APIUtil";
import router from "./routes/routes";
import {Capacitor} from "@capacitor/core";
import {Network} from "@capacitor/network";
import NoInternet from "./pages/staticPages/error/NoInternet";
import {RouterProvider} from 'react-router-dom';
import {GlobalModal} from "./helper/GlobalModal";
import {StorageManager} from "./util/DataUtil";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, persistor, RootState} from "./store/store";
import {
    resetAllRecord,
    updateChefDetails,
} from "./store/slice/AuthSlice";
import {APIConstant} from "./util/APIConstant";
import {ApplicationError} from "./lib/ApplicationError";
import {showToast} from "./helper/GlobalToast";
import {clearCurrentBooking} from "./store/slice/StateUtil";
import { SplashScreen } from '@capacitor/splash-screen';
import {Logo} from "./lib/const";
import { Geolocation } from '@capacitor/geolocation';
import {IUserDetail} from "./lib/customerInterface/interfaces";
import SomethingWentWrong from "./pages/staticPages/error/SomethingWentWrong";
function App() {

    const {showLoader, hideLoader} = useGlobalLoaderContext();
    const userDetail:IUserDetail = useSelector((state: RootState) => state.authSlice);
    const isProfileUpdated = useSelector((state: RootState) => state.authSlice.isProfileUpdated);
    const scrollViewRef = useRef<HTMLDivElement>(null);
    const [isConnected, setIsConnected] = useState(true);
    const [loading, setLoading] = useState(true);


    const dispatch = useDispatch<AppDispatch>();

    console.log("token", userDetail.token);
    useEffect(()=>{

        APIS.intialize(showLoader,hideLoader);
        SplashScreen.show();

        // Simulate loading time before showing the main app content
        setTimeout(() => {
            // SplashScreen.hide();
            setLoading(false);
            console.log("splash called")
        }, 2000);
    },[])
    useEffect(() => {

        dispatch(clearCurrentBooking())

    }, [dispatch]);
    const printCurrentPosition = async () => {
        const coordinates = await Geolocation.getCurrentPosition();
        alert(coordinates.coords.latitude+" latitude"+coordinates.coords.longitude);
        console.log('Current position:', coordinates);
    };
    const getUserDetailFromStorage = async () => {
        // if (userDetail !== null) {
            if (userDetail.token && !isProfileUpdated) {
                // console.log("user detail ", userDetail);
                APIS.getUserDetail(userDetail.customerId,userDetail.token).then(response => {

                    console.log("value of profile completed ", isProfileUpdated);
                    if (response.statusCode === APIConstant.SUCCESS_STATUS_CODE) {
                        console.log("lol ",response)

                        dispatch(updateChefDetails(userDetail));
                    }
                }).catch(err => {
                    if (err instanceof ApplicationError) {
                        console.log("Application error is ",err)
                        switch (err.statusCode) {
                            case APIConstant.BAD_REQUEST:
                                showToast(APIConstant.BAD_REQUEST_MESSAGE);
                                break;
                            case APIConstant.INTERNAL_SERVER_ERROR:
                                showToast(APIConstant.INTERNAL_SERVER_ERROR_MESSAGE);
                                break;
                            case  APIConstant.UNAUTHORIZED: {
                                showToast(APIConstant.UNAUTHORIZED_MESSAGE)
                                StorageManager.deleteData("user")
                                dispatch(resetAllRecord(""))
                                break;
                            }
                        }


                    }
                });
            } else {
                // dispatch(updateProfileStatus(isProfileUpdated));
                dispatch(updateChefDetails(userDetail));
            }
        }

    const getUserDetail = async () => {
        showLoader();
        if (userDetail.token && !isProfileUpdated) {
          await   getUserDetailFromStorage();
            hideLoader();
        } else {
            console.log("no token found")
          await   getUserDetailFromStorage();
            hideLoader();
        }
    }

    useEffect(() => {
        getUserDetail().then(r=> hideLoader());
    }, [userDetail.token, isProfileUpdated]);


    useEffect(() => {
         if (Capacitor.getPlatform() === "web")
                  window.location.href = 'https://www.coyd-service.com';

        if (Capacitor.getPlatform() === "android" || Capacitor.getPlatform() === "ios") {
            const keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', () => {
                scrollViewRef.current?.scrollIntoView({block: "start", behavior: "smooth"});
            });
            return () => {
                keyboardDidHideListener.then(listener => listener.remove);
            };
        }
    }, []);

    useEffect(() => {

        if (Capacitor.getPlatform() === "android" || Capacitor.getPlatform() === "ios") {
            const handleNetworkStatusChange = (status: any) => {
                setIsConnected(status.connected);
            };

            const listener: any = Network.addListener('networkStatusChange', handleNetworkStatusChange);

            return () => {
                listener.remove();
            };
        }
    }, []);

    return (
        <div className="App" ref={scrollViewRef}>
            {loading ? (
                    <div className="splash-screen">
                        <img src={Logo} alt="Loading..." />
                    </div>
                ) :(
                !isConnected ? <NoInternet/> :
                    // <button onClick={()=>printCurrentPosition()}>Click</button>
                <GlobalModal>

                    <RouterProvider router={router}/>

                </GlobalModal>
            )
}
        </div>
    );
}

export default App;
