import './HistoryCard.scss';
import {CONTENT_DATA} from "../../lib/content";
import React from "react";
import {IHistoryResponse} from "../../lib/customerInterface/interfaces";
import {BOOKINGSTATUS, COMMON_RESPONSE} from "../../lib/const";

interface HistoryResponse {
    data: IHistoryResponse;
}

const setBookingStatusClass = (bookingStatus: string) => {
    switch (bookingStatus) {
        case "COMPLETED":
            return 'completed';
        case "PENDING":
            return 'pending';
        case "CONFIRM":
            return 'confirm';
        case "FAILED":
            return 'failed';
        default:
            return ''; // Return an empty string for unknown status
    }
};
const HistoryCard: React.FC<HistoryResponse> = ({data}) => {
    const statusClass = setBookingStatusClass(data.bookingStatus);
    console.log("asd", statusClass);
    console.log(data.bookingStatus);
    return (
        <div className="history-card">

            <p className={`absolute-status ${statusClass}`}>{data.bookingStatus}</p>
            <p className="normal-text">{CONTENT_DATA.history.bookingId}{data.bookingId}</p>
            <p className="normal-text bold">{data.area} | {data.bookedOn}</p>
            <p className="normal-text green">{data.bookingStatus === COMMON_RESPONSE.COMPLETED ? CONTENT_DATA.history.amount.concat(data.amount) : ""}</p>
            <div className="oth-content">
                <p className="normal-text with-border">{data.subscriptionType}</p>
                {data.slots.map(((value, index) => (
                    <p key={index} className="normal-text with-border">{value}</p>
                )))}
                <p className="normal-text with-border">{data.numberOfPerson} Person</p>
            </div>
        </div>

    )
}
export default HistoryCard;