import {CONTENT_DATA} from "../../lib/content";
import HistoryCard from "../../components/historyCard/HistoryCard";
import React, {useEffect, useState} from "react";
import {IHistoryResponse} from "../../lib/customerInterface/interfaces";
import {NoHistory} from "../../lib/const";
import {APIConstant} from "../../util/APIConstant";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store/store";
import {ApplicationError} from "../../lib/ApplicationError";
import {StorageManager} from "../../util/DataUtil";
import {resetAllRecord} from "../../store/slice/AuthSlice";
import {showToast} from "../../helper/GlobalToast";
import PullToRefresh from 'react-pull-to-refresh';
import { ClipLoader } from 'react-spinners';
import APIUtil from "../../util/APIUtil";

const BookingHistory = () => {
    const [data,setData]=useState<IHistoryResponse[]>()
    const customerId=useSelector((state:RootState) =>state.authSlice.customerId)
    const [loading,isLoading]=useState<boolean>(true)

    const dispatch=useDispatch<AppDispatch>();
    useEffect(()=>{
        APIUtil.getBookingHistory()
            .then(result=>{
                if(result.statusCode===APIConstant.SUCCESS_STATUS_CODE) {
                    setData(result.data);
                    isLoading(false)
                }
                else if(result.statusCode===APIConstant.FAILURE_STATUS_CODE) {
                    isLoading(false)
                }
            }).catch(err=>{
            if(err instanceof ApplicationError) {
                switch (err.statusCode) {
                    case APIConstant.BAD_REQUEST:
                        showToast(APIConstant.BAD_REQUEST_MESSAGE);
                        break;
                    case APIConstant.INTERNAL_SERVER_ERROR:
                        showToast(APIConstant.INTERNAL_SERVER_ERROR_MESSAGE);
                        break;
                    case  APIConstant.UNAUTHORIZED: {
                        showToast(APIConstant.UNAUTHORIZED_MESSAGE)
                        StorageManager.deleteData("user")
                        dispatch(resetAllRecord(""))
                        break;
                    }
                }


            }
        });
    },[])

    const DisplayData=():JSX.Element=>{
        if(!loading) {
            if(data) {
              return  <div className="history">
                    <p className="title">{CONTENT_DATA.history.heading}</p>
                    {data?.map((data) => (
                        <HistoryCard key={data.bookingId}  data={data}/>
                    ))}
                </div>
            }
            else {
                return   <div className="history no-history">
                    <img src={NoHistory} alt={"no-history"}/>
                    <p className="title">You don't have a history!!!<br/>Create a history with us</p>
                    <p></p>
                </div>
            }

        }
        else {
            return <></>
        }
    }
    const handleRefresh = async () => {
        // alert("refreshing")
        isLoading(true);
        // Simulate a network request
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                // setItems((prevItems) => ['New Item', ...prevItems]);
                isLoading(false);
                resolve();
            }, 1000);
        });
    };
    return (
        <>
            <PullToRefresh
                onRefresh={handleRefresh}
                icon={
                    <div className="loader">
                        <ClipLoader  size={40} color={"#F00"} loading={loading} />

                    </div>}
            >


            <DisplayData/>
        </PullToRefresh>
            </>
    )
}
export default BookingHistory;