import './StaticPages.scss'
import SecondaryLayout from "../../layout/SecondaryLayout";
import {Form, Formik} from "formik";
import {ProfileUpdateValidation} from "../../schemas/FormErrors";
import {CONTENT_DATA} from "../../lib/content";
import React from "react";
import {profileUpdateImg, ROUTES, TOAST_MESSAGE} from "../../lib/const";
import {useNavigate, useNavigation} from "react-router-dom";
import {StorageManager} from "../../util/DataUtil";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store/store";
import APIS from "../../util/APIUtil";
import {APIConstant} from "../../util/APIConstant";
import {resetAllRecord,  updateUserDetailResponseWhenTrue} from "../../store/slice/AuthSlice";
import {ApplicationError} from "../../lib/ApplicationError";
import {showToast} from "../../helper/GlobalToast";
import {IProfileUpdateRequest, UserDetailResponse} from '../../lib/customerInterface/interfaces';


const ProfileUpdate = () => {
    useSelector((state:RootState)=>state.authSlice.customerId);
    useSelector((state:RootState)=>state.authSlice.token);
    const mobileNumber=useSelector((state:RootState)=>state.authSlice.mobileNumber)
    const dispatch=useDispatch<AppDispatch>();
    const navigate=useNavigate();
    return (
        <SecondaryLayout image={profileUpdateImg}>
            <Formik
                key="ProfileUpdate-form"
                initialValues={{
                    name: "",
                    email: ""
                }}
                validationSchema={ProfileUpdateValidation}
                onSubmit={(values, errors) => {
                    const data:IProfileUpdateRequest= {
                        name:values.name,
                        email:values.email

                    }
         APIS.updateUserProfile(data)
                        .then(res=>{
               if(res.statusCode===APIConstant.SUCCESS_STATUS_CODE) {
                   const detail=res.data;
                   const userDetailResponse:UserDetailResponse={
                       isProfileUpdated:detail.profileCompleted,
                       name:values.name,
                       email:values.email,
                       mobileNumber:mobileNumber

                   }
                  dispatch(updateUserDetailResponseWhenTrue(userDetailResponse));

                   navigate(ROUTES.DASHBOARD);
               }
               else {
                   if(res.statusCode===APIConstant.FAILURE_STATUS_CODE) {
                   showToast(TOAST_MESSAGE.INVALID_NAME_OR_EMAIL);
                   }
               }
           }).catch(err=>{
               if(err instanceof ApplicationError) {
                   switch (err.statusCode) {
                       case APIConstant.BAD_REQUEST:
                           showToast(APIConstant.BAD_REQUEST_MESSAGE);
                           break;
                       case APIConstant.INTERNAL_SERVER_ERROR:
                           showToast(APIConstant.INTERNAL_SERVER_ERROR_MESSAGE);
                           break;
                       case  APIConstant.UNAUTHORIZED: {
                           showToast(APIConstant.UNAUTHORIZED_MESSAGE)
                           StorageManager.deleteData("user")
                           dispatch(resetAllRecord(""))
                           break;
                       }
                   }


               }
           })
                    // Handle form submission

                }}
            >
                {({
                      values,
                      handleChange,
                      handleSubmit,
                      handleBlur,
                      errors,
                  }) => (
                    <Form className="page-wrapper" onSubmit={handleSubmit}>
                        <h1 className="title">{CONTENT_DATA.profileUpdate.heading}</h1>
                        <h2 className="sub-heading">{CONTENT_DATA.profileUpdate.subheading}</h2>
                        <p className="slogan">{CONTENT_DATA.profileUpdate.slogan}</p>
                        <input type="text" name="name" maxLength={CONTENT_DATA.profileUpdate.maxLength}
                               placeholder={CONTENT_DATA.profileUpdate.name}
                               autoComplete="off"
                               onBlur={handleBlur}
                               onChange={handleChange}
                               value={values.name}/>
                        <input type="email" name="email"
                               placeholder={CONTENT_DATA.profileUpdate.email}
                               autoComplete="off"
                               onBlur={handleBlur}
                               onChange={handleChange}
                               value={values.email}/>

                        {errors.name && (
                            <p className="error">{errors.name}</p>
                        )}
                        {!errors.name && errors.email && (
                            <p className="error">{errors.email}</p>
                        )}
                        <button type="submit">{CONTENT_DATA.profileUpdate.btnName}</button>
                    </Form>
                )}
            </Formik>
        </SecondaryLayout>
    )

}
export default ProfileUpdate;