import {useLocation, useNavigate} from "react-router-dom";
import {home, historyLogo, profile, ROUTES, subscription, WhatsAppIcon} from "../../lib/const";
import {CONTENT_DATA} from "../../lib/content";
import {MODAL_TYPES, useGlobalModalContext} from "../../helper/GlobalModal";
import {store} from "../../store/store";

const BottomMenu = () => {
    const {pathname} = useLocation();
    const navigate =useNavigate();
    const activeColor: string = '#FFFFFF';
    const normalColor: string = '#710E03';
    const {hideModal,showModal} = useGlobalModalContext();
    const hideModals=()=>{
        hideModal();
    }
    const menuOptions = [
        {
            name: CONTENT_DATA.bottomMenu.home,
            icon: home,
            activeColor: activeColor,
            route: ROUTES.DASHBOARD,
            onClick: () => {
                hideModals()
                if(store.getState().StateUtil.service.noService || store.getState().StateUtil.service.serviceNotAvailable || !store.getState().StateUtil.autoAddress.area) {

                    console.log("pincode modal dashboard called")
                    console.log(store.getState().StateUtil.service)
                    console.log("------------")
                    console.log(store.getState().StateUtil.autoAddress)
                    showModal(MODAL_TYPES.PINCODE);
                }
                navigate(ROUTES.DASHBOARD)
            }
        },
        {
            name: CONTENT_DATA.bottomMenu.subscription,
            icon: subscription,
            activeColor: activeColor,
            route: ROUTES.BOOKING,
            onClick: () => {
                hideModals()
                navigate(ROUTES.BOOKING)
            },
        },

        {
            name: CONTENT_DATA.bottomMenu.history,
            icon: historyLogo,
            activeColor: activeColor,
            route: ROUTES.HISTORY,
            onClick: () => {
                hideModals()
                navigate(ROUTES.HISTORY)
            }
        },

        {
            name: CONTENT_DATA.bottomMenu.profile,
            icon: profile,
            activeColor: activeColor,
            route: ROUTES.PROFILE,
            onClick: () => {
                hideModals()
                navigate(ROUTES.PROFILE)
            },
        },
        {
            name: CONTENT_DATA.bottomMenu.chatUs,
            icon: WhatsAppIcon,
            activeColor: activeColor,
            // route: ROUTES.PROFILE,
            onClick: () => {
                window.location.href = 'https://wa.me/08296265704'

                    },
        },

    ];
    return (
        <div className={`bottom-menu`}>
            {menuOptions.map((option) => (
                <div
                    className="option"
                    key={option.name}
                    onClick={() => {
                        if (option.onClick) {
                            option.onClick();
                        } else {
                            // navigate(option.route);
                            console.log('a')
                        }
                    }}
                >
                    <img
                        src={option.icon}
                        className={`icon ${pathname === option.route ? "active":""}`}
                        alt={option.name}
                    />
                    <p className="title"
                       style={{color: pathname === option.route ? option.activeColor : normalColor}}>{option.name}</p>
                </div>
            ))}
        </div>
    )
}
export default BottomMenu;